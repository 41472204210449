// React imports
import { useState } from 'react';

// MUI component imports
import { Dialog, DialogContent, Stack, Typography, Button } from '@mui/material';

// My components imports
import { getObjectType } from "./misc";
import MovimientoListItem from "../components/basic/movimientoListItem.js";
import MovimientoTarjetaListItem from "../components/basic/movimientoTarjetaListItem";
import TransferenciaListItem from "../components/basic/transferenciaListItem";
import PagoDeTarjetaListItem from "../components/basic/pagoDeTarjetaListItem";
import RegisterMovement from "../components/app/registerMovement";
import RegisterCardMovement from "../components/app/registerCardMovement";
import RegisterTransfer from "../components/app/registerTransfer";
import RegisterCurrencyExchange from "../components/app/registerCurrencyExchange";
import RegisterCardPayment from "../components/app/registerCardPayment";
import MovimientoProgramadoListItem from "../components/basic/movimientoProgramadoListItem.js";
import MovimientoTarjetaProgramadoListItem from "../components/basic/movimientoTarjetaProgramadoListItem";
import LoteDeAccionEnCuentaListItem from "../components/basic/investment/loteDeAccionEnCuentaListItem.js";
import CreateModifyLoteDeAccion from "../components/app/investment/createModifyLoteDeAccion.js";

function getObjectListItem(obj, { ...props }) {
  if (getObjectType(obj).type === "Movimiento") {
    return <MovimientoListItem movimiento={obj} {...props} />
  };
  if (getObjectType(obj).type === "MovimientoTarjeta") {
    return <MovimientoTarjetaListItem movimientoTarjeta={obj} {...props} />
  };
  if (getObjectType(obj).type === "Transferencia") {
    return <TransferenciaListItem transferencia={obj} {...props} />
  };
  if (getObjectType(obj).type === "PagoDeTarjeta") {
    return <PagoDeTarjetaListItem pagoDeTarjeta={obj} {...props} />
  };
  if (getObjectType(obj).type === "LoteDeAccion") {
    return <LoteDeAccionEnCuentaListItem loteDeAccion={obj} {...props} />
  }
}

function getRegisterObject(obj, { ...props }) {
  if (getObjectType(obj).type === "Movimiento") {
    return <RegisterMovement initialValues={obj} allowSwitching={true} {...props} />
  };
  if (getObjectType(obj).type === "MovimientoTarjeta") {
    return <RegisterCardMovement allowSwitching={true} initialValues={obj} {...props} />
  };
  if (getObjectType(obj).type === "Transferencia") {
    if (getObjectType(obj).variant === "Transferencia") {
      return <RegisterTransfer initialValues={obj} {...props} />
    } else {
      return <RegisterCurrencyExchange initialValues={obj} {...props} />
    }
  };
  if (getObjectType(obj).type === "PagoDeTarjeta") {
    return <RegisterCardPayment initialValues={obj} {...props} />
  };
  if (getObjectType(obj).type === "LoteDeAccion") {
    return <CreateModifyLoteDeAccion initialLoteDeAccion={obj} {...props} />
  }
  // Object type not found, returning empty object
  return <></>
}
/**
 * Function that returns a list item for the correct visual representation of a scheduled register 
 * @param {MovimientoProgramado|MovimientoTarjetaProgramado} obj - Scheduled register object
 * @param {*} param1 
 * @returns {React.Component} - List item component
 */
function getScheduledListItem(obj, { ...props }) {
  if (getObjectType(obj).type === "MovimientoProgramado") {
    return <MovimientoProgramadoListItem movimiento={obj} {...props} />
  } else if (getObjectType(obj).type === "MovimientoTarjetaProgramado") {
    return <MovimientoTarjetaProgramadoListItem movimiento={obj} {...props} />
  } else {
    console.error('Tipo de objeto no reconocido:', getObjectType(obj));
    return <></>
  }
}

function generateListItemComponent() {

  return function ({ ClassicComponent, CompactComponent, confirmationText, compact = true, onModify, onDelete, ...props }) {
    // State constants
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

    // Handlers
    const handleModifyClick = event => {
      event.stopPropagation();
      onModify();
    }

    const handleDeleteClick = event => {
      event.stopPropagation();
      setShowDeleteConfirmationDialog(true);
    }

    const handleDeleteConfirmation = () => {
      setShowDeleteConfirmationDialog(true);
      onDelete();
    }

    const handleDeleteCancel = event => {
      event.stopPropagation();
      setShowDeleteConfirmationDialog(false);
    }

    return <>
      <Dialog open={showDeleteConfirmationDialog} onClose={() => setShowDeleteConfirmationDialog(false)}>
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Typography variant="body">{confirmationText}</Typography>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" color="error" onClick={handleDeleteConfirmation} fullWidth>Sí, adelante</Button>
              <Button variant="contained" color="cancel" sx={{ color: "white" }} onClick={handleDeleteCancel} fullWidth>No, cancelar</Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      {compact ?
        <CompactComponent onModify={handleModifyClick} onDelete={handleDeleteClick} {...props} /> :
        <ClassicComponent onModify={handleModifyClick} onDelete={handleDeleteClick} {...props} />
      }
    </>
  }
}


export { getObjectListItem, getRegisterObject, getScheduledListItem, generateListItemComponent };
// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Chip, Stack, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useTheme } from "@mui/material";

// My component imports
import { getAmountText, getCategoryIcon } from "../../utils/misc";
import { dateToDisplayFormat } from "../../utils/date";
import { movementPropType } from "../../utils/myPropTypes";


function MovimientoListItemCompact({ movimiento, onDelete, onModify }) {

  // Other constants
  const theme = useTheme();
  const ammountColor = movimiento.monto >= 0 ? theme.palette.number.positive : theme.palette.number.negative;


  return <Stack direction="row" spacing={2} sx={{ cursor: "pointer" }} onClick={onModify}>

    <Stack direction="column" justifyContent={"center"} sx={{ color: theme.palette.primary.main }}>
      {getCategoryIcon(movimiento ? movimiento.categoria.nombre : null, "medium")}
    </Stack>

    <Stack direction="column">

      <Stack direction="row" flexWrap={"wrap"}>
        {movimiento.etiquetas.map(label => {
          return <Chip
            key={label.id}
            label={label.texto}
            variant="outlined"
            color="primary"
            size="small"
            sx={{ marginRight: "2px", marginBottom: "2px", fontSize: "0.7rem" }}
          />
        })}
      </Stack>

      <Stack direction="row" spacing={1} alignItems={"center"}>
        {movimiento.movimiento_programado_id && <SmartToyIcon sx={{ fontSize: 15, color: theme.palette.disabled.main }} />}
        <Typography variant="caption">{dateToDisplayFormat(movimiento.fecha) + ' - ' + movimiento.cuenta.nombre + ' (' + movimiento.cuenta.tipo_de_cuenta.nombre + ')'}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="subtitle1" color={ammountColor} sx={{ fontWeight: "bold" }}>{getAmountText(movimiento.monto, movimiento.cuenta.moneda)}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="caption" sx={{ fontStyle: "italic", color: theme.palette.primary.lightText }}>{movimiento.comentario}</Typography>
      </Stack>
    </Stack>

    <Stack direction="column" justifyContent={"center"} alignItems={"flex-end"} flexGrow={1}>
      <DeleteIcon onClick={onDelete} color="cancel" sx={{ fontSize: 25 }} />
    </Stack>

  </Stack>
}

MovimientoListItemCompact.propTypes = {
  movimiento: movementPropType, // value to initialize data inputs
  onDelete: PropTypes.func, // Function to be called when user whats to delete Movimiento
  onModify: PropTypes.func, // Function to be called when user whats to modify Movimiento
};

export default MovimientoListItemCompact;
// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import TransferenciaListItemCompact from "./transferenciaListItemCompact.js";
import TransferenciaListItemClassic from "./transferenciaListItemClassic.js";
import { transferPropType } from '../../utils/myPropTypes.js';
import { generateListItemComponent } from '../../utils/visualizations.js';

function TransferenciaListItem({ transferencia, onDelete, onModify, pointOfView, compact }) {
  const ListItemComponent = generateListItemComponent();

  return (
    <ListItemComponent
      ClassicComponent={TransferenciaListItemClassic}
      CompactComponent={TransferenciaListItemCompact}
      transferencia={transferencia}
      onDelete={onDelete}
      onModify={onModify}
      pointOfView={pointOfView}
      compact={compact}
    />
  );
}

TransferenciaListItem.propTypes = {
  transferencia: transferPropType.isRequired, // Transferencia object to be shown
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete Movimiento
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify Movimiento
  pointOfView: PropTypes.object, // Account object that determines from which perspective to show transfer or currency exchange
  compact: PropTypes.bool, // If true, show only the compact version of the item
};

export default TransferenciaListItem;
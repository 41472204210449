// React imports
import { useState, useContext, useEffect } from "react";

// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from '@mui/material/Drawer';
import Typography from "@mui/material/Typography";
import AppRegistrationTwoToneIcon from '@mui/icons-material/AppRegistrationTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EditNoteTwoToneIcon from '@mui/icons-material/EditNoteTwoTone';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import Badge from '@mui/material/Badge';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import SchoolIcon from '@mui/icons-material/School';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import List from '@mui/material/List';
import { styled } from '@mui/system';

// My components imports
import { urls } from "../../settings.js";
import UserAvatar from "./userAvatar.js";
import { logoutUser } from "../../utils/authUtils.js";
import MyListItem from "./myListItem.js";
import appLogo from "../../static_content/FinanzasUY.svg";
// import appLogo from "../../static_content/FinanzasUY_sin_cuadro.svg";
import AppUpdateContext from "../../utils/appUpdate.js";
import { Button } from "@mui/material";
import { userHasActiveInvestmentSubscription } from "../../utils/subscription.js";

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

function Menu(props) {

  // Constants
  const userHasInvestmentSubscription = userHasActiveInvestmentSubscription();

  // State constants
  const [menuOpen, setMenuOpen] = useState(false);
  const { appUpdate, setAppUpdate } = useContext(AppUpdateContext);

  // Other constants
  const history = useHistory();

  // Handlers
  const handleUpdateClick = () => {
    // Refresh page to get the updated App code
    window.location.reload();
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>

          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={event => history.push(urls.homeUrl)}
          >
            <HomeIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setMenuOpen(menuState => !menuState)}
          >
            {appUpdate ?
              <Badge color="error" variant="dot">
                <MenuIcon />
              </Badge> : <MenuIcon />
            }
          </IconButton>

        </Toolbar>
      </AppBar>

      <Offset />

      <Drawer
        anchor={props.position}
        open={menuOpen}
        onClose={(event) => setMenuOpen(false)}
      >
        <Stack spacing={1} flexGrow={1} role="presentation" sx={{ maxWidth: "80vW" }}>

          <Stack direction="row" sx={{ margin: "15px", marginLeft: "0px" }}>
            <Stack
              direction="column"
              spacing={1}
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: "5px", cursor: "pointer" }}
              onClick={event => history.push(urls.homeUrl)}
              flexGrow={1}
            >
              <img src={appLogo} alt="FinanzasUY" width={"87%"} />
            </Stack>
            <UserAvatar />
          </Stack>

          <Divider />

          <Stack spacing={1} sx={{ paddingLeft: "10px", paddingRight: "5px" }}>
            <MyListItem onClick={event => history.push(urls.registrationsUrl)} sx={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="center" spacing={3}>
                <AppRegistrationTwoToneIcon color="primary" fontSize="large" /><Typography color="primary.dark">Registrar</Typography>
              </Stack>
            </MyListItem>
            <MyListItem onClick={event => history.push(urls.visualizationsUrl)} sx={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="center" spacing={3}>
                <AssessmentTwoToneIcon color="primary" fontSize="large" /><Typography color="primary.dark">Analizar</Typography>
              </Stack>
            </MyListItem>
            <MyListItem onClick={event => history.push(urls.searchMovementUrl)} sx={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="center" spacing={3}>
                <SearchTwoToneIcon color="primary" fontSize="large" /><Typography color="primary.dark">Actividad</Typography>
              </Stack>
            </MyListItem>
            <MyListItem onClick={event => history.push(urls.configureCustodyAgentUrl)} sx={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="center" spacing={3}>
                <AccountBalanceIcon color="primary" fontSize="large" /><Typography color="primary.dark">Lugares de custodia</Typography>
              </Stack>
            </MyListItem>
            <MyListItem onClick={event => history.push(urls.configureDebtAccountsUrl)} sx={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="center" spacing={3}>
                <EditNoteTwoToneIcon color="primary" fontSize="large" /><Typography color="primary.dark">Compromisos financieros</Typography>
              </Stack>
            </MyListItem>
            <MyListItem onClick={event => history.push(urls.configurationsUrl)} sx={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="center" spacing={3}>
                <SettingsTwoToneIcon color="primary" fontSize="large" /><Typography color="primary.dark">Configurar</Typography>
              </Stack>
            </MyListItem>
            {userHasInvestmentSubscription &&
              <MyListItem onClick={event => history.push(urls.investmentsUrl)} sx={{ cursor: "pointer" }}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <TrendingUpIcon color="primary" fontSize="large" /><Typography color="primary.dark">Inversiones</Typography>
                </Stack>
              </MyListItem>
            }

          </Stack>

          <Stack spacing={1} justifyContent="flex-end" flexGrow={1}>
            <Divider />
            <List>
              <MyListItem onClick={() => history.push(urls.tutorialUrl)} sx={{ cursor: "pointer" }}>
                <Stack direction="row" alignItems="center" spacing={2} sx={{ paddingLeft: "10px" }}>
                  <SchoolIcon color="primary" fontSize="large" /><Typography color="primary.dark">Aprende a usar la App</Typography>
                </Stack>
              </MyListItem>
              <MyListItem onClick={event => history.push(urls.subscriptionUrl)} sx={{ cursor: "pointer" }}>
                <Stack direction="row" alignItems="center" spacing={2} sx={{ paddingLeft: "10px" }}>
                  <CardMembershipIcon color="primary" fontSize="large" /><Typography color="primary.dark">Suscripción</Typography>
                </Stack>
              </MyListItem>
              <MyListItem onClick={event => history.push(urls.supportUrl)} sx={{ cursor: "pointer" }}>
                <Stack direction="row" alignItems="center" spacing={2} sx={{ paddingLeft: "10px" }}>
                  <SupportAgentIcon color="primary" fontSize="large" /><Typography color="primary.dark">Soporte</Typography>
                </Stack>
              </MyListItem>
              <MyListItem onClick={logoutUser} sx={{ cursor: "pointer" }}>
                <Stack direction="row" alignItems="center" spacing={2} sx={{ paddingLeft: "10px" }}>
                  <LogoutTwoToneIcon color="primary" fontSize="large" /><Typography color="primary.dark">Cerrar sesión</Typography>
                </Stack>
              </MyListItem>
              {appUpdate ?
                <MyListItem onClick={handleUpdateClick}>
                  <Stack direction="row" alignItems="center" spacing={2} sx={{ paddingLeft: "10px" }}>
                    <Badge color="error" badgeContent="!">
                      <Button variant="outlined" size="small" color="primary">Actualizar a nueva versión</Button>
                    </Badge>
                  </Stack>
                </MyListItem> : null
              }
            </List>
          </Stack>

        </Stack>
      </Drawer >
    </Box >
  )

}

export default Menu;
// Possible component libraries to use
// Grommet: https://v2.grommet.io/
// Rebass: https://rebassjs.org/
// Chakra: https://chakra-ui.com/
// Ant Design: https://ant.design/ (parece ser el mas indicado)

// Font imports
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// MUI imports
import { ThemeProvider } from '@mui/material/styles';

// React imports
import { useState, useEffect } from 'react';

// React Router imports
import { Switch, Route } from "react-router-dom";

// // My style imports
// import './App.css';

// My component imports
import WithAuth from './utils/auth.js';
import WithSubscription from './utils/subscription.js';
import Home from './Home.js';
import UserProfile from './components/app/userProfile.js';
import Configurations from './components/app/configurations.js';
import Visualizations from './components/app/visualizations.js';
import DataRegistrations from './components/app/dataRegistrations';
import ConfigureCard from './components/app/configureCard';
import ConfigureCardsDates from './components/app/configureCardsDates';
import ConfigureCardDate from './components/app/configureCardDate';
import RegisterExpense from './components/app/registerExpense';
import RegisterIncome from './components/app/registerIncome';
import RegisterTransferPage from './components/app/registerTransferPage';
import RegisterCurrencyExchangePage from './components/app/registerCurencyExchangePage';
import SearchMovement from './components/app/searchMovement';
import Searches from './components/app/searches';
import { urls } from "./settings.js";
import themes from "./styles/themes.js";
import { getUser, getUserProfile } from "./utils/api.js";
import ConfigureAccount from './components/app/configureAccount';
import CardCurrentMovementsPage from './components/app/cardCurrentMovmementsPage';
import CardAccountStatesPage from './components/app/cardAccountStatesPage';
import VisualizeCuerrencyExchange from './components/app/visualizeCurrencyEchange';
import VisualizeExpenses from './components/app/visualizeExpenses';
import VisualizeCashFlow from './components/app/visualizeCashFlow';
import VisualizeMonthlyExpensesPage from './components/app/visualizeMonthlyExpensesPage.js';
import AdminPage from './components/app/adminPage';
import ConfigureCustodyAgent from './components/app/configureCustodyAgent.js';
import ConfigureCustodyAgents from './components/app/configureCustodyAgents.js';
import ConfigureFamilyPage from './components/app/configureFamilyPage';
import ConfigureLabelsPage from './components/app/configureLabelsPage';
import VisualizeBalance from './components/app/visualizeBalance';
import ConfigureDebtAccount from './components/app/configureDebtAccount';
import { UserProfileContext } from './utils/userProfile.js';
import { UserContext } from './utils/userContext.js';
import AppUpdateContext from './utils/appUpdate.js';
import ConfigureApp from './components/app/configureApp.js';
import ServiceWorkerContext from './utils/serviceWorkerContext.js';
import SubscriptionPage from './components/app/subscriptionPage.js';
import TutorialPage from './components/app/tutorialPage.js';
import SupportPage from './components/app/supportPage.js';
import AgendasPage from './components/app/agendasPage.js';
import ConfigureScheduledRegistersPage from './components/app/configureScheduledRegistersPage.js';
import Investments from './components/app/investment/investments.js';
import PortfolioPage from './components/app/investment/portfolioPage.js';

function App() {

  // State constants
  const [currentTheme, setCurrentTheme] = useState(themes.primary);
  const [appUpdate, setAppUpdate] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);

  document.title = 'FinanzasUY';

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    // Load user profile
    getUserProfile()
      .then(perfil => isMounted && setProfile(perfil))
      .catch(err => console.log(err));
    getUser()
      .then(user => isMounted && setUser(user))
      .catch(err => console.log(err));
    return () => isMounted = false;
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && profile) {
      setCurrentTheme(themes[profile.tema ? profile.tema.split('.')[1] : 'primary']);
    }
    return () => isMounted = false;
  }, [profile]);

  useEffect(() => {
    let isMounted = true;
    const registerServiceWorker = async () => {
      if ('serviceWorker' in navigator) {
        try {
          const myRegistration = await navigator.serviceWorker.register('/serviceWorker.js');
          if (isMounted) {
            setRegistration(myRegistration);
          }
        } catch (e) {
          console.error('Error durante el registro del ServiceWorker:', e);
        }
      }
    };

    // Register the Service Worker immediately if possible
    registerServiceWorker();

    // Cleanup function
    return () => isMounted = false;
  }, []);


  useEffect(() => {
    let isMounted = true;
    let intervalId = null;
    if (registration !== null && isMounted) {
      // Setea un intervalo para que el navegador verifique si hay un nuevo service worker
      intervalId = setInterval(() => {
        registration.update();
      }, 30000); // Cada 30 segundos

      // Escucha el evento de actualización encontrada en el registro
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
            console.log('Nueva actualización disponible, notificando al usuario');
            setAppUpdate(true);
          }
        };
      };
    }

    // Cleanup function
    return () => {
      clearInterval(intervalId);
      isMounted = false;
    }

  }, [registration])

  return (
    <ServiceWorkerContext.Provider value={registration}>
      <AppUpdateContext.Provider value={{ appUpdate, setAppUpdate }}>
        <ThemeProvider theme={currentTheme}>
          <UserProfileContext.Provider value={{ profile, setProfile }}>
            <UserContext.Provider value={{ user, setUser }}>
              <Switch>
                <Route path={urls.adminUrl}>
                  <AdminPage />
                </Route>
                <Route path={urls.configureCustodyAgentUrl + '/:custodyAgentId'}>
                  <ConfigureCustodyAgent />
                </Route>
                <Route path={urls.configureCustodyAgentUrl}>
                  <ConfigureCustodyAgents />
                </Route>
                <Route path={urls.configureDebtAccountsUrl}>
                  <ConfigureDebtAccount />
                </Route>
                <Route path={urls.configureAccountsUrl + '/:accountId'}>
                  <ConfigureAccount />
                </Route>
                <Route path={urls.visualizeCashFlow}>
                  <VisualizeCashFlow />
                </Route>
                <Route path={urls.visualizeBalance}>
                  <VisualizeBalance />
                </Route>
                <Route path={urls.configureLabels}>
                  <ConfigureLabelsPage />
                </Route>
                <Route path={urls.configureCardsUrl + '/:cardId/dates/:dateId'}>
                  <ConfigureCardDate />
                </Route>
                <Route path={urls.configureCardsUrl + '/:cardId/dates'}>
                  <ConfigureCardsDates />
                </Route>
                <Route path={urls.configureCardsUrl + '/:cardId/movements'}>
                  <CardCurrentMovementsPage />
                </Route>
                <Route path={urls.configureCardsUrl + '/:cardId/accountStates'}>
                  <CardAccountStatesPage />
                </Route>
                <Route path={urls.configureCardsUrl + '/:cardId'}>
                  <ConfigureCard />
                </Route>
                <Route path={urls.configureFamily}>
                  <ConfigureFamilyPage />
                </Route>
                <Route path={urls.configureApp}>
                  <ConfigureApp />
                </Route>
                <Route path={urls.configurationsUrl}>
                  <Configurations />
                </Route>
                <Route path={urls.visualizeExpenses}>
                  <VisualizeExpenses />
                </Route>
                <Route path={urls.visualizeCurrencyExchange}>
                  <VisualizeCuerrencyExchange />
                </Route>
                <Route path={urls.visualizeMonthlyExpenses}>
                  <VisualizeMonthlyExpensesPage />
                </Route>
                <Route path={urls.visualizationsUrl}>
                  <Visualizations />
                </Route>
                <Route path={urls.searchMovementUrl}>
                  <SearchMovement />
                </Route>
                <Route path={urls.searchUrl}>
                  <Searches />
                </Route>
                <Route path={urls.registerExpenseUrl}>
                  <RegisterExpense />
                </Route>
                <Route path={urls.registerIncomeUrl}>
                  <RegisterIncome />
                </Route>
                <Route path={urls.registerTransferUrl}>
                  <RegisterTransferPage />
                </Route>
                <Route path={urls.registerCurrencyExchangeUrl}>
                  <RegisterCurrencyExchangePage />
                </Route>
                <Route path={urls.registrationsUrl}>
                  <DataRegistrations />
                </Route>
                <Route path={urls.userProfileUrl}>
                  <UserProfile changeProfile={prof => setProfile(prof)} />
                </Route>
                <Route path={urls.subscriptionUrl}>
                  <SubscriptionPage />
                </Route>
                <Route path={urls.tutorialUrl}>
                  <TutorialPage />
                </Route>
                <Route path={urls.supportUrl}>
                  <SupportPage />
                </Route>
                <Route path={urls.schedulesUrl}>
                  <AgendasPage />
                </Route>
                <Route path={urls.scheduledMovementsUrl}>
                  <ConfigureScheduledRegistersPage />
                </Route>
                <Route path={urls.investmentsUrl}>
                  <Investments />
                </Route>
                <Route path={urls.portafolioUrl}>
                  <PortfolioPage />
                </Route>
                <Route path={urls.homeUrl}>
                  <Home />
                </Route>
              </Switch>
            </UserContext.Provider>
          </UserProfileContext.Provider>
        </ThemeProvider>
      </AppUpdateContext.Provider>
    </ServiceWorkerContext.Provider>
  )

}


export default WithAuth(WithSubscription(App));
// React router imports
import { useParams } from 'react-router-dom';

// React imports
import { useState, useEffect, useRef } from 'react';

// MUI imports
import { Stack } from '@mui/system';
import { Dialog, DialogContent, Snackbar, Alert } from '@mui/material';

// My component imports
import AccountHeader from '../basic/accountHeader';
import MyMenuComponent from './myMenuComponent';
import { urls } from '../../settings';
import { getUserAccount, getCategories, getMovements, getTransfers, getCurrencies, getUserAccounts, getCards, getCardPayments, getCustodyAgents } from '../../utils/api';
import NewMovementList from '../lists/newMovementList.js';
import MyLoadingList from '../basic/myLoadingList';
import CreateModifyAccount from './createModifyAccount';
import MyPaper from '../basic/myPaper';
import BasicSummaryTable from '../basic/basicSummaryTable';
import { emptyFilterAccountMovement } from '../../utils/constants';
import { deepObjectCopy, getMixedMovementsListBasedOnQueryObject } from '../../utils/misc';
import { useUserProfile } from '../../utils/userProfile';
import FilterMovements from '../filters/filterMovements';
import { getCardPaymentsQuery, getMovementsQuery, getTransfersQuery, getExchangesQuery } from '../../utils/queries';
import { userHasActiveInvestmentSubscription } from '../../utils/subscription';


function ConfigureAccount(props) {

  // Constants
  const { accountId } = useParams();
  const searchObject = useRef(null);
  const userHasInvestmentSubscription = userHasActiveInvestmentSubscription();

  // State constants
  const { profile } = useUserProfile();
  const [account, setAccount] = useState(null);
  const [cards, setCards] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [movementsLoaded, setMovementsLoaded] = useState(false);
  const [movementList, setMovementList] = useState([]);
  const [currentSearchObject, setCurrentSearchObject] = useState(emptyFilterAccountMovement);
  const [updateList, setUpdateList] = useState(true);
  const [showAccountForm, setShowAccountForm] = useState(false);
  const [custodyAgents, setCustodyAgents] = useState([]);
  const [navigationLinks, setNavigationLinks] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    setInitialized(false);
    setMovementsLoaded(false);
    Promise.all([getUserAccount(accountId), getCategories(), getCurrencies(), getCards(), getUserAccounts(), getCustodyAgents()])
      .then(responses => {
        if (isMounted) {
          setAccount(responses[0]);
          setCategories(responses[1].results);
          setCurrencies(responses[2].results);
          setCards(responses[3].results);
          setAccounts(responses[4].results);
          setCustodyAgents(responses[5].results);
          if (searchObject.current && searchObject.current.accounts.length > 0) {
            // This is to maintain searchObject between renders
            setCurrentSearchObject(searchObject.current);
          } else {
            // Initialize with an empty searchObject
            let newFilter = deepObjectCopy(emptyFilterAccountMovement);
            newFilter.accounts = [responses[0]];
            if (!userHasInvestmentSubscription) {
              // Remove stockBatches from movementTypes
              newFilter.movementTypes = newFilter.movementTypes.filter(type => type !== 'stockBatches');
            }
            setCurrentSearchObject(newFilter);
          }
        }
      })
      .catch(err => console.log(err));
    return () => { isMounted = false }
  }, [accountId, updateList, userHasInvestmentSubscription])

  useEffect(() => {
    let isMounted = true;
    if (account !== null && account.moneda && categories.length > 0 && currentSearchObject && currentSearchObject.accounts.length > 0 && custodyAgents.length > 0 && isMounted) {
      setInitialized(true);
    }
    return () => { isMounted = false }
  }, [account, categories, currentSearchObject, custodyAgents])

  useEffect(() => {
    let isMounted = true;
    let promises = [];
    if (initialized && isMounted) {
      // Display loading skeleton while constructing the new movementList
      setMovementsLoaded(false);

      // Set ready to false to avoid displaying the movementList before it is ready
      setReady(false);

      // Construct promises based on currentQueryObject
      if ((currentSearchObject.movementTypes.includes('income') || currentSearchObject.movementTypes.includes('expenses'))) {
        promises.push(getMovements(getMovementsQuery(currentSearchObject)));
      }
      if (currentSearchObject.movementTypes.includes('cardPayments')) promises.push(getCardPayments(getCardPaymentsQuery(currentSearchObject)));
      if (currentSearchObject.movementTypes.includes('transfers')) promises.push(getTransfers(getTransfersQuery(currentSearchObject)));
      if (currentSearchObject.movementTypes.includes('exchanges')) promises.push(getTransfers(getExchangesQuery(currentSearchObject)));

      getMixedMovementsListBasedOnQueryObject(currentSearchObject)
        .then(movs => {
          setMovementList(movs);
          setReady(true);
        })
        .catch(err => setShowErrorMessage(true));
    }
    return () => { isMounted = false }
  }, [initialized, currentSearchObject])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && initialized && ready) {
      setMovementsLoaded(true);
    }
    return () => { isMounted = false }
  }, [movementList, initialized, ready])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && account !== null) {
      if (account.tipo_de_cuenta.nombre !== 'Cuenta de deuda') {
        setNavigationLinks([
          { url: urls.configurationsUrl, name: 'Configurar' },
          { url: urls.configureCustodyAgentUrl, name: 'Lugares de custodia' },
          { url: urls.configureCustodyAgentUrl + '/' + (account ? account.agente_de_custodia.id : ''), name: (account ? account.agente_de_custodia.nombre : '') }
        ])
      } else {
        setNavigationLinks([
          { url: urls.homeUrl, name: 'Inicio' },
          { url: urls.configureDebtAccountsUrl, name: 'Compromisos financieros' },
        ])
      }
    }
    return () => isMounted = false;
  }, [account])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !movementsLoaded) {
      // Empty movementList whenever the loader is shown
      setMovementList([]);
    }
  }, [movementsLoaded])

  // Handlers
  const handleMovementSearch = searchObj => {
    setCurrentSearchObject(searchObj);
  }

  const handleMovementChanged = () => {
    // When I excecute updateList it will triger a re-render of the whole element, so I must preserv currentSearchObject
    searchObject.current = currentSearchObject;
    setUpdateList(prev => !prev)
  }

  return <MyMenuComponent
    links={navigationLinks}
  // currentPageName={"Cuentas"}
  >
    <Snackbar open={showErrorMessage} autoHideDuration={2000} onClose={() => setShowErrorMessage(false)} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} tran>
      <Alert severity='error'>Algo no funcionó, intenta nuevamente</Alert>
    </Snackbar>

    <Dialog open={showAccountForm} onClose={() => setShowAccountForm(false)}>
      <DialogContent>
        <CreateModifyAccount
          initialValues={{
            initialAccount: account,
            intent: 'modify',
          }}
          currencies={currencies}
          custodyAgents={custodyAgents}
          onSubmit={() => {
            setShowAccountForm(false);
            setUpdateList(prev => !prev);
          }}
          onCancel={() => setShowAccountForm(false)}
        />
      </DialogContent>
    </Dialog>

    <Stack>
      {initialized ? <>
        <MyPaper>
          <AccountHeader
            cuenta={account}
            onClick={(cuenta) => setShowAccountForm(true)}
          />
        </MyPaper>
        <FilterMovements
          initialValues={currentSearchObject}
          onSearchStarted={handleMovementSearch}
          accounts={accounts}
          cards={cards}
          categories={categories}
          hideAccountSelector
          hideCardSelector
          hideCashCreditSelector
          dateHelperValues={['hoy', 'ayer', 'inicioDeMes', 'mesPasado']}
          searchOnFilterChange
        />
      </>
        :
        <MyPaper><MyLoadingList /></MyPaper>
      }

      {movementsLoaded ?
        <Stack>
          <NewMovementList
            movements={movementList}
            updateList={handleMovementChanged}
            cards={cards}
            accounts={accounts}
            categories={categories}
            pointOfView={account}
            compact={profile.tipo_de_lista === 'ValidListTypes.compact'}
          />
          <MyPaper>
            <BasicSummaryTable
              movementList={movementList}
              account={account}
            />
          </MyPaper>
        </Stack> :
        <MyPaper><MyLoadingList /></MyPaper>
      }
    </Stack>


  </MyMenuComponent>
}

ConfigureAccount.propTypes = {
};

export default ConfigureAccount;
// MUI imports
import { Stack, Typography, Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useTheme } from "@mui/material";

// My component imports
import AccionIcon from "./accionIcon";
import { dateToDisplayFormat } from "../../../utils/date";
import { getAmountText } from "../../../utils/misc";
import { parseName } from "../../../utils/misc";
import { getCustodyAgentTypeIcon } from "../../../utils/misc";

function LoteDeAccionEnCuentaListItemClassic({ loteDeAccion, onModify, onDelete }) {

  // Other constants
  const theme = useTheme();
  const amountColor = loteDeAccion.precio >= 0 ? theme.palette.number.positive : theme.palette.number.negative;

  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{ padding: "25px", cursor: "pointer" }} onClick={onModify}>
      <Stack>
        <Stack direction="column" spacing={1}>

          <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
            <Chip
              // color="primary"
              label={loteDeAccion.fecha}
              size="medium"
              icon={<CalendarMonthIcon fontSize="small" />}
              variant="outlined"
            />
            <Chip
              // color="primary"
              label={parseName(loteDeAccion.cuenta.nombre)}
              size="medium"
              icon={getCustodyAgentTypeIcon(loteDeAccion.agente_de_custodia.tipo.nombre, "small")}
              variant="outlined"
            />
          </Stack>
          <Stack direction="row">
            <Chip
              // color="primary"
              label={"compraventa de acciones"}
              size="medium"
              icon={<ShowChartIcon fontSize="small" />}
              variant="outlined"
            />
          </Stack>
        </Stack>
        {/* <Typography variant="caption">
          {dateToDisplayFormat(loteDeAccion.fecha) + ' - ' + loteDeAccion.agente_de_custodia.nombre + ' (' + loteDeAccion.cuenta.nombre + ')'}
        </Typography> */}
        {/* <AccionIcon accion={loteDeAccion.accion} /> */}
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle1" color={amountColor} sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>{getAmountText(loteDeAccion.precio, loteDeAccion.cuenta.moneda)}</Typography>
        </Stack>
        <Typography variant="caption">Compra de {loteDeAccion.cantidad} acciones de {loteDeAccion.accion.ticker}</Typography>
      </Stack>
      <Stack direction="column" justifyContent={"center"} alignItems={"flex-end"} flexGrow={1}>
        <DeleteIcon color="cancel" sx={{ fontSize: 35, cursor: "pointer" }} onClick={onDelete} />
      </Stack>
    </Stack>
  );
}

export default LoteDeAccionEnCuentaListItemClassic;
// React imports
import { useState } from 'react';

// MUI imports
import { Avatar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// My components imports
import { accionPropType } from '../../../utils/myPropTypes';

function AccionIcon({ accion, size = null }) {

  // Constants
  const theme = useTheme();

  // State constants
  const [imageOK, setImageOK] = useState(accion.icon_url ? true : false);

  return imageOK ?
    <Avatar variant="square" style={{ backgroundColor: "white" }}>
      <img
        src={accion.icon_url}
        alt={accion.nombre}
        style={{
          width: size ? size : "80%",
          height: size ? size : "80%",
          objectFit: "contain",
          backgroundColor: "white",
          paddingRight: "10px",
        }}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = setImageOK(false);
        }}
      />
    </Avatar> :
    <Avatar
      variant="square"
      style={{
        backgroundColor: "white",
        border: `1px solid ${theme.palette.primary.main}`,
        width: size ? size : "28px",
        height: size ? size : "28px",
        marginRight: "10px",
      }}
    >
      <Typography
        variant="body"
        sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}
      >
        {accion.ticker[0]}
      </Typography>
    </Avatar>

}

AccionIcon.propTypes = {
  accion: accionPropType.isRequired,
};

export default AccionIcon;
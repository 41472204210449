import { apiDateSerializator, getDateFromDateTime } from "./date";
import { set } from 'date-fns';

const SUCCESS_FEEDBACK_TIMER = 500;
const MOVEMENT_TYPES = ['expenses', 'income', 'transfers', 'exchanges', 'cardPayments', 'stockBatches'];
const MOVEMENT_TYPES_DICT = {
  'expenses': 'Gastos',
  'income': 'Ingresos',
  'transfers': 'Transferencias',
  'exchanges': 'Cambio de moneda',
  'cardPayments': 'Pagos de tarjeta',
  'stockBatches': 'Compraventa de acciones',
}

const emptyFilterMovement = {
  initialDate: getDateFromDateTime(set(new Date(), { date: 1 })),
  finalDate: getDateFromDateTime(new Date()),
  cashCredit: 'both',
  movementTypes: MOVEMENT_TYPES,
  accounts: [],
  cards: [],
  categories: [],
  labels: [],
  filterLabelsWithAnd: false,
  commentContains: '',
}

const emptyFilterExpensesAndIncome = {
  initialDate: getDateFromDateTime(set(new Date(), { date: 1 })),  // First day of current month 
  finalDate: getDateFromDateTime(new Date()),  // Today
  accounts: [],
  cards: [],
  categories: [],
  labels: [],
  filterLabelsWithAnd: false,
  cashCredit: "both",
  amountLte: 0,
  amountGte: 0,
  filterAmountWithAnd: false,
  commentContains: '',
  labelsDialogOpen: false,
}

const emptyFilterAccountMovement = {
  initialDate: getDateFromDateTime(set(new Date(), { date: 1 })),
  finalDate: getDateFromDateTime(new Date()),
  cashCredit: 'cash',
  movementTypes: MOVEMENT_TYPES,
  accounts: [],
  cards: [],
  categories: [],
  labels: [],
  filterLabelsWithAnd: false,
  commentContains: '',
}

const emptyFilterMonthlyExpenses = {
  initialDate: getDateFromDateTime(set(new Date(), { date: 1, month: new Date().getMonth() - 5 })),
  finalDate: getDateFromDateTime(set(new Date(), { date: 1 })),
  categories: [],
  labels: [],
  filterLabelsWithAnd: false,
}

const emptyMovement = {
  cuenta: null,
  categoria: null,
  etiquetas: [],
  fecha: getDateFromDateTime(new Date()),
  comentario: '',
  monto: 0,
  id: undefined,
}

const emptyScheduledMovement = {
  agenda: null,
  cuenta: null,
  categoria: null,
  etiquetas: [],
  comentario: '',
  monto: 0,
  id: undefined,
}

const emptyScheduledCardMovement = {
  agenda: null,
  tarjeta: null,
  categoria: null,
  etiquetas: [],
  comentario: '',
  monto: 0,
  cuotas: 1,
  id: undefined,
}

const emptyAccount = {
  nombre: '',
  agente_de_custodia: null,
  moneda: null,
  tipo_de_cuenta: null,
  numero: '',
  balance: 0,
}

const emptyFamily = {
  id: undefined,
  nombre: '',
}

const emptyCard = {
  id: undefined,
  nombre: '',
  numero: '',
  fecha_de_cierre_actual: getDateFromDateTime(new Date()),
  fecha_de_vencimiento_actual: getDateFromDateTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 10)),
  agente_de_custodia: null,
  tope_de_credito: 0,
  saldos_de_tarjeta: [],
  moneda: null,
}

const emptyCardMovement = {
  tarjeta: null,
  categoria: null,
  etiquetas: [],
  fecha: getDateFromDateTime(new Date()),
  comentario: '',
  monto: 0,
  id: undefined,
  cantidad_de_cuotas: 1,
  moneda: null,
  saldo_de_tarjeta: null,
  estado_de_cuenta: null,
}

const emptyTransfer = {
  cuenta_origen: null,
  monto_origen: 0,
  cuenta_destino: null,
  monto_destino: 0,
  etiquetas: [],
  comentario: '',
  fecha: getDateFromDateTime(new Date()),
}

const emptyCardPayment = {
  id: undefined,
  saldo_de_tarjeta_id: null,
  saldo_de_tarjeta: null,
  tarjeta: null,
  cuenta: null,
  monto_origen: 0,
  monto_destino: 0,
  etiquetas: [],
  comentario: '',
  fecha: getDateFromDateTime(new Date()),
}

const emptyCustodyAgent = {
  id: undefined,
  nombre: '',
  tipo: null,
  pais: null,
}

const emptyFamilyInvitation = {
  id: undefined,
  correo: '',
  familia: null,
  revocada: false,
}

const emptyAgenda = {
  nombre: 'Agenda 1',
  tipo_periodicidad: 'semanal',
  dia_semana: 1,
  dia_mes: 1,
  mes: 1,
  fecha_inicio: getDateFromDateTime(new Date()), // Today's date,
  fecha_fin: getDateFromDateTime(new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())) // A year from today
}

const tiposPeriodicidad = [
  { label: 'Diaria', value: 'diaria' },
  { label: 'Semanal', value: 'semanal' },
  { label: 'Mensual', value: 'mensual' },
  { label: 'Anual', value: 'anual' },
];

const diasSemana = [
  { label: 'Lunes', value: 0 },
  { label: 'Martes', value: 1 },
  { label: 'Miércoles', value: 2 },
  { label: 'Jueves', value: 3 },
  { label: 'Viernes', value: 4 },
  { label: 'Sábado', value: 5 },
  { label: 'Domingo', value: 6 },
];

export {
  emptyFilterMovement,
  emptyFilterExpensesAndIncome,
  emptyFilterAccountMovement,
  emptyFilterMonthlyExpenses,
  emptyMovement,
  emptyCardMovement,
  emptyTransfer,
  emptyCardPayment,
  emptyCustodyAgent,
  emptyAccount,
  emptyCard,
  emptyFamily,
  emptyFamilyInvitation,
  emptyAgenda,
  emptyScheduledMovement,
  emptyScheduledCardMovement,
  SUCCESS_FEEDBACK_TIMER,
  MOVEMENT_TYPES,
  MOVEMENT_TYPES_DICT,
  tiposPeriodicidad,
  diasSemana,
};
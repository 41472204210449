// MUI imports
import { Stack } from "@mui/material";
import { styled } from '@mui/system';

function MyColumnStack(props) {

  const ObjectToReturn = styled(Stack)(() => ({
    maxWidth: '650px',
    width: '90vw',
  }));

  return <ObjectToReturn {...props}>
    {props.children}
  </ObjectToReturn>;

}

export default MyColumnStack;
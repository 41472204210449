// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, Chip, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';

// My components imports
import { getAmountText, parseName } from '../../utils/misc';
import { cardPaymentPropType } from '../../utils/myPropTypes';

function PagoDeTarjetaListItemClassic({ pagoDeTarjeta, onModify, onDelete, ...props }) {

  // State constants
  const [labelsToShow, setLabelsToShow] = useState([]);

  // Constants
  const theme = useTheme();
  const ammountText = pagoDeTarjeta ?
    pagoDeTarjeta.cuenta.moneda.nombre_corto + ' ' + getAmountText(pagoDeTarjeta.monto_origen) : '';

  // Effect hooks
  useEffect(() => {
    if (pagoDeTarjeta) {
      let myLabels = JSON.parse(JSON.stringify(pagoDeTarjeta.etiquetas));
      let labelsStack = myLabels.map(label => {
        return <Chip
          key={label.id}
          label={label.texto}
          variant="outlined"
          size="small"
          color="primary"
          sx={{ marginRight: "2px", marginBottom: "2px" }}
        />
      });
      setLabelsToShow(labelsStack);
    }
  }, [pagoDeTarjeta])

  return <Stack direction="row" onClick={onModify} sx={{ padding: "25px" }}>

    <Stack direction="column" flexGrow={1}>

      <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
        <Chip
          label={pagoDeTarjeta.fecha}
          size="medium"
          icon={<CalendarMonthIcon fontSize="small" />}
          variant="outlined"
        />
        <Chip
          label={parseName(pagoDeTarjeta.cuenta.nombre)}
          size="medium"
          icon={<AccountBalanceIcon fontSize="small" />}
          variant="outlined"
        />
      </Stack>

      <Stack direction="row" spacing={1}>
        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
          <Typography sx={{
            fontWeight: 'bold',
            alignSelf: 'center',
            paddingBottom: '2px',
            fontSize: '1.1rem',
            color: theme.palette.error.main
          }}
          >
            {ammountText}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="column">
        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
          <Typography variant="subtitle2">Pago de tarjeta:</Typography>
          <Chip
            label={pagoDeTarjeta.tarjeta.nombre}
            size="small"
            icon={<CreditCardIcon fontSize="small" />}
            variant="outlined"
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
          <Stack>
            <Typography variant="subtitle2">
              {"Deuda cancelada:"}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1">
              {pagoDeTarjeta.saldo_de_tarjeta.moneda.nombre_corto + ' ' + getAmountText(pagoDeTarjeta.monto_destino)}
            </Typography>
          </Stack>
        </Stack>

      </Stack>

      <Stack direction="row" sx={{ flexWrap: "wrap" }}>
        {labelsToShow}
      </Stack>

    </Stack>


    <Stack direction="column" justifyContent="center" alignItems="flex-end">
      <DeleteIcon onClick={onDelete} color="cancel" sx={{ fontSize: 35 }} />
    </Stack>

  </Stack>
}

PagoDeTarjetaListItemClassic.propTypes = {
  pagoDeTarjeta: cardPaymentPropType, // PagoDeTarjeta to be displayed
  onModify: PropTypes.func.isRequired, // Function to be called when user clicks to modify payment
  onDelete: PropTypes.func.isRequired, // Function to be called when user clicks to delete payment
}

export default PagoDeTarjetaListItemClassic;
// React imports
import { useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material";

// My component imports
import AccionIcon from "./accionIcon";
import { dateToDisplayFormat } from "../../../utils/date";
import { getAmountText } from "../../../utils/misc";
import { loteDeAccionPropType } from '../../../utils/myPropTypes';

function LoteDeAccionEnCuentaListItemCompact({ loteDeAccion, onModify, onDelete }) {

  // Constants
  const theme = useTheme();
  const amountColor = loteDeAccion.precio >= 0 ? theme.palette.number.positive : theme.palette.number.negative;

  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={onModify} sx={{ cursor: "pointer" }}>
      <AccionIcon accion={loteDeAccion.accion} size="20px" />
      <Stack>
        <Typography variant="caption">
          {dateToDisplayFormat(loteDeAccion.fecha) + ' - ' + loteDeAccion.agente_de_custodia.nombre + ' (' + loteDeAccion.cuenta.nombre + ')'}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle1" color={amountColor} sx={{ fontWeight: "bold" }}>{getAmountText(loteDeAccion.precio, loteDeAccion.cuenta.moneda)}</Typography>
        </Stack>
        <Typography variant="caption">Compra de {loteDeAccion.cantidad} acciones de {loteDeAccion.accion.ticker}</Typography>
      </Stack>
      <Stack direction="column" justifyContent={"center"} alignItems={"flex-end"} flexGrow={1}>
        <DeleteIcon color="cancel" sx={{ fontSize: 25, cursor: "pointer" }} onClick={onDelete} />
      </Stack>
    </Stack>
  );
}

// PropTypes
LoteDeAccionEnCuentaListItemCompact.propTypes = {
  loteDeAccion: loteDeAccionPropType.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default LoteDeAccionEnCuentaListItemCompact;
// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, Chip, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CreditCardIcon from '@mui/icons-material/CreditCard';

// My components imports
import { getAmountText } from '../../utils/misc';
import { dateToDisplayFormat } from '../../utils/date';

function PagoDeTarjetaListItemCompact({ pagoDeTarjeta, onModify, onDelete, ...props }) {

  // Constants
  const theme = useTheme();
  const ammountColor = pagoDeTarjeta ?
    (pagoDeTarjeta.monto_origen >= 0 ? theme.palette.primary.success : theme.palette.primary.error) :
    undefined;

  return <Stack direction="row" spacing={2} onClick={onModify}>

    <Stack direction="column" justifyContent={"center"}>
      <CreditCardIcon size="medium" sx={{ color: theme.palette.primary.main }} />
    </Stack>

    <Stack direction="column">

      <Stack direction="row" flexWrap={"wrap"}>
        {pagoDeTarjeta.etiquetas.map(label => {
          return <Chip
            key={label.id}
            label={label.texto}
            variant="outlined"
            color="primary"
            size="small"
            sx={{ marginRight: "2px", marginBottom: "2px", fontSize: "0.7rem" }}
          />
        })}
      </Stack>

      <Stack direction="row" spacing={1}>
        <Typography variant="caption">
          {dateToDisplayFormat(pagoDeTarjeta.fecha) + ' - ' + pagoDeTarjeta.cuenta.nombre + ' (' + pagoDeTarjeta.cuenta.tipo_de_cuenta.nombre + ') --> ' + pagoDeTarjeta.tarjeta.nombre}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Typography variant="subtitle1" color={ammountColor} sx={{ fontWeight: "bold" }}>
          {getAmountText(pagoDeTarjeta.monto_origen, pagoDeTarjeta.cuenta.moneda)}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Typography variant="caption" sx={{ fontStyle: "italic", color: theme.palette.primary.lightText }}>
          {"Deuda cancelada: " + getAmountText(pagoDeTarjeta.monto_destino, pagoDeTarjeta.saldo_de_tarjeta.moneda)}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Typography variant="caption" sx={{ fontStyle: "italic", color: theme.palette.primary.lightText }}>{pagoDeTarjeta.comentario}</Typography>
      </Stack>

    </Stack>


    <Stack direction="column" justifyContent={"center"} alignItems={"flex-end"} flexGrow={1}>
      <DeleteIcon onClick={onDelete} color="cancel" sx={{ fontSize: 25 }} />
    </Stack>

  </Stack>
}

PagoDeTarjetaListItemCompact.propTypes = {
  pagoDeTarjeta: PropTypes.object.isRequired, // PagoDeTarjeta to be displayed
  onModify: PropTypes.func.isRequired, // Function to be called when user clicks to modify payment
  onDelete: PropTypes.func.isRequired, // Function to be called when user clicks to delete payment
}

export default PagoDeTarjetaListItemCompact;
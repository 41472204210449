// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI components imports
import { Alert, Button, Typography, Stack, Dialog, DialogContent } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

// My components imports
import LotesDeAccionList from '../../lists/investment/lotesDeAccionList';
import MyLoadingList from '../../basic/myLoadingList';
import { accountPropType, custodyAgentPropType } from '../../../utils/myPropTypes';
import { getStockBatches } from '../../../utils/investmentApi';
import { reformatStockBatches } from '../../../utils/investment/misc';
import CreateModifyLoteDeAccion from './createModifyLoteDeAccion';
import { getCustodyAgents } from '../../../utils/api';
import MyPaper from '../../basic/myPaper';

function LoteDeAccionManager({ fixedCustodyAgent = null, accounts, onChange }) {

  // State constants
  const [loteDeAccionList, setLoteDeAccionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showBatchForm, setShowBatchForm] = useState(false);
  const [custodyAgents, setCustodyAgents] = useState(fixedCustodyAgent ? [fixedCustodyAgent] : [])

  // Auxiliary functions
  const buildList = async () => {
    setLoading(true);
    return getStockBatches(fixedCustodyAgent)
      .then(response => {
        setLoteDeAccionList(reformatStockBatches(response.results));
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }

  // Effects
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      buildList();
    }
    return () => { isMounted = false }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && custodyAgents.length > 0) {
      buildList();
    }
    return () => { isMounted = false }
  }, [custodyAgents]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !fixedCustodyAgent) {
      getCustodyAgents()
        .then(response => {
          setCustodyAgents(response.results.filter(agent => agent.tipo.nombre == "Corredor de bolsa"))
        })
        .catch(err => console.error(err))
    }
    return () => { isMounted = false }
  }, [fixedCustodyAgent])

  // Handlers
  const handleListUpdateRequest = () => {
    setShowBatchForm(false);
    buildList();
    onChange && onChange();
  }

  return <>
    {loading ? <MyPaper><MyLoadingList /></MyPaper> :
      error ? <Alert severity="error">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body">
            Error cargando información sobre acciones y ETFs
          </Typography>
          <Button variant="outlined" onClick={() => window.location.reload()} fullWidth>Recargar <ReplayIcon /></Button>
        </Stack>
      </Alert> :
        <Stack>
          <Dialog
            onClose={() => setShowBatchForm(false)}
            open={showBatchForm}
          >
            <DialogContent>
              <CreateModifyLoteDeAccion
                intent="create"
                onSubmit={handleListUpdateRequest}
                onCancel={() => setShowBatchForm(false)}
                fixedCustodyAgent={fixedCustodyAgent}
                custodyAgents={custodyAgents}
                accounts={accounts}
                initialLoteDeAccion={null}
              />
            </DialogContent>
          </Dialog>
          <Stack spacing={1}>
            {custodyAgents.length > 0 &&
              <LotesDeAccionList
                lotesDeAccionList={loteDeAccionList}
                onUpdate={handleListUpdateRequest}
                fixedCustodyAgent={fixedCustodyAgent}
                custodyAgents={custodyAgents}
                accounts={accounts}
                onRegisterClick={() => setShowBatchForm(true)}
              />}
          </Stack>
        </Stack>}
  </>
}

// PropTypes
LoteDeAccionManager.propTypes = {
  accounts: PropTypes.arrayOf(accountPropType).isRequired,
  fixedCustodyAgent: custodyAgentPropType,
  onChange: PropTypes.func,  // Callback to parent component
}

export default LoteDeAccionManager;


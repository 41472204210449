// React imports
import { useState, useEffect } from "react";

// JWT imports
import jwt_decode from "jwt-decode";

// My components imports
import { getSubscriptions } from "./billingApi";
import SubscribePage from "../components/app/subscribePage.js";
import { forceAccessTokenRefresh } from "./authUtils.js";

// With subscription wrapper
function WithSubscription(WrappedComponent) {

  return function ExtendedComponent() {

    // State variables
    const [userIsSubscribed, setUserIsSubscribed] = useState(false);
    const [checkingSubscription, setCheckingSubscription] = useState(true);

    // Auxiliary functions
    const checkSubscription = async () => {
      try {
        const subscriptions = await getSubscriptions();
        if (subscriptions.results.length > 0) {
          setUserIsSubscribed(true);
          setCheckingSubscription(false);
          forceAccessTokenRefresh();  // Refresh access token so I get sure the scopes corresponding to the subscription are loaded in case of a brand new subscription
        } else {
          setUserIsSubscribed(false);
          setCheckingSubscription(false);
        }
      } catch (err) {
        setUserIsSubscribed(false);
        setCheckingSubscription(false);
      }
    }

    // Effect hooks
    useEffect(() => {
      // Check if user has an active subscription
      let isMounted = true;
      isMounted && checkSubscription();
      return () => { isMounted = false };
    }, [])


    return userIsSubscribed ?
      <WrappedComponent /> :
      <SubscribePage checkingSubscription={checkingSubscription} />

  }
}

export async function userIsSubscribed() {
  // Function to check if user has an active subscription

  let subscriptions = await getSubscriptions();
  if (subscriptions.results && subscriptions.results.length > 0) {
    return true;
  } else {
    return false;
  }

}

export function userHasActiveInvestmentSubscription() {
  // Get access token from local storage
  const jwtToken = localStorage.getItem('accessToken');
  // Decode token
  const accessToken = jwtToken ? jwt_decode(jwtToken) : null;
  // If there is no access token, return false
  if (!accessToken) return false;
  // See if "investment" is inside scopes array
  const investmentScopeFound = accessToken.scopes.includes("investment");
  if (investmentScopeFound) {
    return true;
  } else {
    return false;
  }
}

export default WithSubscription;
// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import { generateListItemComponent } from '../../utils/visualizations';
import PagoDeTarjetaListItemClassic from './pagoDeTarjetaListItemClassic';
import PagoDeTarjetaListItemCompact from './pagoDeTarjetaListItemCompact';
import { cardPaymentPropType } from '../../utils/myPropTypes';

function PagoDeTarjetaListItem({ pagoDeTarjeta, onModify, onDelete, compact, ...props }) {

  const ListItemComponent = generateListItemComponent();

  return (
    <ListItemComponent
      ClassicComponent={PagoDeTarjetaListItemClassic}
      CompactComponent={PagoDeTarjetaListItemCompact}
      pagoDeTarjeta={pagoDeTarjeta}
      onModify={onModify}
      onDelete={onDelete}
      compact={compact}
      confirmationText="Esta acción no se puede deshacer, si te arrepientes tendrás que volver a registrar el pago de tarjeta. ¿Avanzamos?"
      {...props}
    />
  );
}

PagoDeTarjetaListItem.propTypes = {
  pagoDeTarjeta: cardPaymentPropType, // PagoDeTarjeta to be displayed
  onModify: PropTypes.func.isRequired, // Function to be called when user clicks to modify payment
  onDelete: PropTypes.func.isRequired, // Function to be called when user clicks to delete payment
  compact: PropTypes.bool.isRequired, // Whether to display the item in compact mode or not
}

export default PagoDeTarjetaListItem;
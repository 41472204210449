function reformatStockBatches(data) {
  const groupedData = {};

  data.forEach(item => {
    const ticker = item.accion.ticker;

    if (!groupedData[ticker]) {
      groupedData[ticker] = {
        ...item.accion,
        batches: []
      };
    }

    groupedData[ticker].batches.push(item);
  });

  return Object.values(groupedData);
}

function calculateStockHoldingsValues(stock, currentQuote) {
  // Function that receives the batches of a particular stock and the most current quote and returns:
  // {
  //    currentHoldingsValue: Float, the value of all the stock according to the current quote
  //    costs: Float, the total amount of money used to buy the stock
  //    ingress: Float, the total amount of money received from selling the stock
  //    actualCosts: Float, the total amount of money used to buy the stock minus the total amount of money received from selling the stock
  //    numberOfStocks: Float, the total amount of stocks bought minus the total amount of stocks sold
  //    growthValue: Float, the difference between currentHoldingsValue and actualCosts
  //    growthPercentage: Float, the percentage of growth of the stock
  // }
  let costs = 0;
  let ingress = 0;
  let actualCosts = 0;
  let numberOfStocks = 0;
  let growthPercentage = 0;
  stock.batches.forEach(batch => {
    const precio = parseFloat(batch.precio);
    const cantidad = parseFloat(batch.cantidad);
    if (precio > 0) { // It is a sell
      ingress += precio;
      numberOfStocks -= cantidad;
    } else { // It is a buy
      costs += precio;
      numberOfStocks += cantidad;
    }
  });
  actualCosts = costs + ingress;
  const currentHoldingsValue = currentQuote * numberOfStocks;
  const growthValue = currentHoldingsValue + actualCosts;
  if (currentHoldingsValue > -actualCosts) {
    growthPercentage = -((currentHoldingsValue + actualCosts) / actualCosts) * 100;
  }
  else if (currentHoldingsValue < -actualCosts) {
    growthPercentage = -((actualCosts + currentHoldingsValue) / currentHoldingsValue) * 100;
  }
  return {
    currentHoldingsValue,
    costs,
    ingress,
    actualCosts,
    numberOfStocks,
    growthValue,
    growthPercentage
  }
}

function calculatePortfolioHoldingsValues(portafolio, stockQuotesObject) {
  // Function that receives the portfolio and using the function calculateStockHoldingsValues returns:
  // {
  //    currentHoldingsValue: Float, the value of all the stock according to the current quote
  //    costs: Float, the total amount of money used to buy the stock
  //    ingress: Float, the total amount of money received from selling the stock
  //    actualCosts: Float, the total amount of money used to buy the stock minus the total amount of money received from selling the stock
  //    growthValue: Float, the difference between currentHoldingsValue and actualCosts
  //    growthPercentage: Float, the percentage of growth of the stock
  // }
  // The function should return the sum of all the values of the stocks in the portfolio
  // Number of stocks does not make sense in this context as it is impossible to multiplicate stocks from different companies with different prices
  let costs = 0;
  let ingress = 0;
  let actualCosts = 0;
  let growthPercentage = 0;
  let currentHoldingsValue = 0;
  if (!stockQuotesObject || Object.keys(stockQuotesObject).length === 0) return {
    currentHoldingsValue,
    costs,
    ingress,
    actualCosts,
    growthValue: 0,
    growthPercentage: 0
  };
  portafolio.forEach(stock => {
    const stockValues = calculateStockHoldingsValues(stock, stockQuotesObject[stock.ticker].cierre);
    costs += stockValues.costs;
    ingress += stockValues.ingress;
    actualCosts += stockValues.actualCosts;
    currentHoldingsValue += stockValues.currentHoldingsValue;
  });
  const growthValue = currentHoldingsValue + actualCosts;
  if (currentHoldingsValue > -actualCosts) {
    growthPercentage = -((currentHoldingsValue + actualCosts) / actualCosts) * 100;
  }
  else if (currentHoldingsValue < -actualCosts) {
    growthPercentage = -((actualCosts + currentHoldingsValue) / currentHoldingsValue) * 100;
  }
  return {
    currentHoldingsValue,
    costs,
    ingress,
    actualCosts,
    growthValue,
    growthPercentage
  }
}



export { reformatStockBatches, calculateStockHoldingsValues, calculatePortfolioHoldingsValues };
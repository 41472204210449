
// React imports
import { useEffect, useState } from 'react';

// MercadoPago SDK imports
import {
  initMercadoPago,
  createCardToken,
  CardNumber,
  SecurityCode,
  ExpirationDate,
  getIdentificationTypes,
  getPaymentMethods,
} from '@mercadopago/sdk-react';

// My component imports
import CedulaInput from '../../inputs/cedulaInput';
import { Button } from '@mui/material';


// Styles for MercadoPago Secure Fields
const secureFieldStyle = {
  height: '49px',
  marginTop: '5px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  padding: '0 0 5px 5px',
  // Match the style of the text with the style of the TextField component in MUI
  fontFamily: 'Roboto, sans-serif',
  fontSize: '0.8rem',
  fontWeight: '400',
  lineHeight: '1.5',
  letterSpacing: '0.00938em',
  color: 'rgba(0, 0, 0, 0.87)',
};

// Function that gets card data from the user and logs card token to the console
function MercadoPagoCreateCard() {

  initMercadoPago(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY);

  // State constants
  const [cedula, setCedula] = useState(null);

  useEffect(() => {
    getIdentificationTypes()
      .then(response => {
        console.log('Identification Types Response = ', response);
      })
  }, []);


  // Handlers
  const handleCeludaChange = newCedula => {
    console.log('New Cedula = ', newCedula);
    setCedula(newCedula);
  }

  const cardToken = async () => {
    const response = await createCardToken({
      cardholderName: 'Ismael Almandos',
      identificationType: 'CI',
      identificationNumber: cedula.toString(),
    })
    console.log('Card Token Response = ', response);
    const paymentMethods = await getPaymentMethods({ bin: response.first_six_digits });
    console.log('Payment Methods = ', paymentMethods);
    const paymentMethodId = paymentMethods.results[0].id;
    const issuerId = paymentMethods.results[0].issuer.id;
    console.log('Payment Method ID = ', paymentMethodId);
    console.log('Issuer ID = ', issuerId);
  }

  return <>
    <div style={secureFieldStyle}>
      <CardNumber
        style={secureFieldStyle}
        placeholder='Número de tarjeta'
      />
    </div>

    <div style={secureFieldStyle}>
      <ExpirationDate
        style={secureFieldStyle}
        placeholder='MM/AA'
      />
    </div>

    <div style={secureFieldStyle}>
      <SecurityCode
        style={secureFieldStyle}
        placeholder='CVV'
      />
    </div>

    <CedulaInput
      initialCedula={cedula}
      onChange={handleCeludaChange}
    />

    <Button variant="contained" onClick={cardToken} sx={{ marginTop: '5px' }}>Guardar tarjeta</Button>
  </>

}

export default MercadoPagoCreateCard;

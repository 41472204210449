// React imports
import { useState, useEffect } from 'react';

// React Router imports
import { useHistory } from "react-router-dom";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Alert, Stack, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// My component imports
import { getCustodyAgentsTypes, getCustodyAgents, deleteCustodyAgent, getCountries } from '../../utils/api';
import MyMenuComponent from './myMenuComponent';
import MyPaper from '../basic/myPaper';
import { emptyCustodyAgent } from '../../utils/constants';
import MyLoadingList from '../basic/myLoadingList';
import AddButton from '../basic/addButton';
import CreateModifyCustodyAgent from './createModifyCustodyAgent';
import CustodyAgentListItem from '../basic/custodyAgentListItem';
import { urls } from '../../settings';
import CustodyAgentsList from '../lists/custodyAgentsList';


function ConfigureCustodyAgent(props) {

  // Other constants
  const history = useHistory();
  const emptyInitialValues = emptyCustodyAgent;

  // State constants
  const [custodyAgentsTypes, setCustodyAgentsTypes] = useState([]);
  const [custodyAgents, setCustodyAgents] = useState([]);
  const [showCustodyAgentForm, setShowCustodyAgentForm] = useState(false);
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [updateCustodyAgentList, setUpdateCustodyAgentList] = useState(false);
  const [countries, setCountries] = useState([]);
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    Promise.all([getCustodyAgentsTypes(), getCustodyAgents(), getCountries()])
      .then(responses => {
        if (isMounted) {
          // Set Custody Agents Types filtering out the 'Deudas' type
          setCustodyAgentsTypes(responses[0].results.filter(custodyAgentType => custodyAgentType.nombre !== 'Deudas'));
          setCustodyAgents(responses[1].results);
          setCountries(responses[2].results);
        }
      })
      .catch(error => {
        console.log(error);
      });
    return () => { isMounted = false }
  }, [updateCustodyAgentList]);

  // Handlers
  const handleDeleteCustodyAgent = (custodyAgent) => {
    deleteCustodyAgent(custodyAgent.id)
      .then(response => {
        setUpdateCustodyAgentList(!updateCustodyAgentList);
      })
      .catch(error => { console.log(error) });
  }

  const handleCreateModifyCustodyAgent = (values) => {
    setReady(false);
    setShowCustodyAgentForm(false);
    setUpdateCustodyAgentList(!updateCustodyAgentList);
  }

  const handleAddButtonClick = () => {
    setShowCustodyAgentForm(true);
    setInitialValues(emptyInitialValues);
  }

  const handleCancelOperation = () => {
    setShowCustodyAgentForm(false);
  }

  return <MyMenuComponent
    links={[
      // { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.homeUrl, name: 'Inicio' },
    ]}
  // currentPageName={"Agentes de custodia"}
  >
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Stack spacing={1}>
        {showCustodyAgentForm ?
          <MyPaper>
            {custodyAgentsTypes.length > 0 && countries.length > 0 ?
              <CreateModifyCustodyAgent
                initialValues={{
                  initialCustodyAgent: initialValues,
                  intent: initialValues.id ? 'modify' : 'create',
                }}
                custodyAgentTypes={custodyAgentsTypes}
                countries={countries}
                onSubmit={handleCreateModifyCustodyAgent}
                onCancel={handleCancelOperation}
              /> : <MyLoadingList />
            }

          </MyPaper> :
          <Stack>
            {custodyAgents.length === 0 &&
              <Stack>
                <MyPaper>
                  <Alert severity="info">
                    <Stack direction={"row"} spacing={1}>
                      <Typography variant='subtitle1'>
                        Toca
                        <AddCircleIcon sx={{ fontSize: "20px", marginLeft: "5px", marginRight: "5px" }} color="primary" />
                        más abajo para configurar un agente de custodia para poder comenzar a utilizar la App.
                        Recuerda, un agente de custudia es un lugar donde guardas tu dinero o activos, por ejemplo un banco o tu billetera.
                      </Typography>
                    </Stack>
                  </Alert>
                </MyPaper>
              </Stack>
            }
            <CustodyAgentsList
              custodyAgents={custodyAgents}
              onDelete={handleDeleteCustodyAgent}
            />
            <AddButton onClick={handleAddButtonClick} />
          </Stack>
        }
      </Stack>
    </Stack>

  </MyMenuComponent>
}

ConfigureCustodyAgent.propTypes = {
  custodyAgents: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ConfigureCustodyAgent;
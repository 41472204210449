// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Chip, Stack, Typography, useTheme } from "@mui/material";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

// My component imports
import DeleteIcon from '@mui/icons-material/Delete';
import { getAmountText, getCategoryIcon } from "../../utils/misc";
import { scheduledMovementPropType } from '../../utils/myPropTypes';
import { generateAgendaPeriodicityText } from "./agendaListItem";


function MovimientoProgramadoListItemCompact({ movimiento, onModify, onDelete }) {

  // Other constants
  const theme = useTheme();
  const ammountColor = movimiento.monto >= 0 ? theme.palette.number.positive : theme.palette.number.negative;

  return <Stack
    direction="column"
    spacing={1}
    sx={{ cursor: "pointer", backgroundColor: theme.palette.primary.main, borderRadius: "5px", padding: "3px" }}
    onClick={onModify}
  >

    <Stack direction="column" alignItems={"flex-start"} sx={{ padding: '3px' }}>
      <Stack direction={"row"} spacing={1}>
        <EditCalendarIcon color="primary" sx={{ fontSize: 15, color: "white" }} />
        <Typography variant="caption" fontWeight={"bold"}>{movimiento.agenda.nombre.toUpperCase()}</Typography>
      </Stack>
      {generateAgendaPeriodicityText(movimiento.agenda)}
    </Stack>

    <Stack direction="row" spacing={1} justifyContent={"center"} alignItems={"center"} sx={{ backgroundColor: "white", borderRadius: "3px", padding: "3px" }}>
      <Stack direction="column" justifyContent={"center"} sx={{ color: theme.palette.primary.main }}>
        {getCategoryIcon(movimiento ? movimiento.categoria.nombre : null, "medium")}
      </Stack>

      <Stack>

        <Stack direction="row" flexWrap={"wrap"}>
          {movimiento.etiquetas.map(label => {
            return <Chip
              key={label.id}
              label={label.texto}
              variant="outlined"
              color="primary"
              size="small"
              sx={{ marginRight: "2px", marginBottom: "2px", fontSize: "0.7rem" }}
            />
          })}
        </Stack>

        <Stack direction="row" spacing={1}>
          <Typography variant="caption">{movimiento.cuenta.nombre + ' (' + movimiento.cuenta.tipo_de_cuenta.nombre + ')'}</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle1" color={ammountColor} sx={{ fontWeight: "bold" }}>{getAmountText(movimiento.monto, movimiento.cuenta.moneda)}</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="caption" sx={{ fontStyle: "italic", color: theme.palette.primary.lightText }}>{movimiento.comentario}</Typography>
        </Stack>
      </Stack>

      <Stack direction="column" justifyContent={"center"} alignItems={"flex-end"} flexGrow={1}>
        <DeleteIcon onClick={onDelete} color="cancel" sx={{ fontSize: 25 }} />
      </Stack>
    </Stack>

  </Stack>
}

MovimientoProgramadoListItemCompact.propTypes = {
  movimiento: scheduledMovementPropType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
};

export default MovimientoProgramadoListItemCompact;
// React imports
import { useState } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { TextField } from '@mui/material';

function CedulaInput(props) {

  // Decontructing the props
  const { onChange, initialCedula } = props;

  const [cedula, setCedula] = useState(initialCedula);
  const [error, setError] = useState('');

  // Handlers
  const handleChange = (e) => {
    const cedula = e.target.value;
    setCedula(cedula);
    if (isValidCedula(cedula)) {
      setError('');
    } else {
      setError('Cédula inválida');
    }
  }

  const handleBlur = () => {
    if (cedula) {
      onChange(cedula);
    }
  }

  const isValidCedula = (cedula) => {
    const cedulaWithoutVerifier = cedula.replace(/\D/g, '').slice(0, -1);
    const verifierDigit = parseInt(cedula.slice(-1));
    const cedulaWithoutVerifierArray = cedulaWithoutVerifier.split('').map(Number);
    const weights = [2, 9, 8, 7, 6, 3, 4];
    const sum = cedulaWithoutVerifierArray.reduce((acc, curr, index) => {
      return acc + curr * weights[index];
    }, 0);
    const remainder = sum % 10;
    const calculatedVerifierDigit = remainder === 0 ? 0 : 10 - remainder;
    return verifierDigit === calculatedVerifierDigit;
  }

  return (
    <>
      <TextField
        label='Cédula'
        placeholder='Cédula del titular de la tarjeta'
        value={cedula}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error.length > 0}
        helperText={error}
        type='number'
        fullWidth
        sx={{ marginTop: "5px" }}
      />
    </>
  );
}

CedulaInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialCedula: PropTypes.number,
}

export default CedulaInput;
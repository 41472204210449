// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack } from '@mui/material';

// My components imports
import LoteDeAccionForm from '../../forms/investment/loteDeAccionForm';
import { accountPropType, custodyAgentPropType, loteDeAccionPropType } from '../../../utils/myPropTypes';
import { registerStockBatch, modifyStockBatch } from '../../../utils/investmentApi';

function CreateModifyLoteDeAccion({ intent = "create", initialLoteDeAccion = null, fixedCustodyAgent, custodyAgents, accounts, onSubmit, onCancel }) {

  // Handlers
  const handleSubmit = (loteDeAccion) => {
    if (intent === "create") {
      registerStockBatch(loteDeAccion)
        .then(onSubmit)
        .catch((error) => console.error(error));
    } else if (intent === "modify") {
      modifyStockBatch(loteDeAccion)
        .then(onSubmit)
        .catch((error) => console.error(error));
    } else {
      console.error("Invalid intent:", intent);
    }
  }

  return <Stack>
    <LoteDeAccionForm
      intent={intent}
      initialLoteDeAccion={initialLoteDeAccion}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      custodyAgents={custodyAgents}
      fixedCustodyAgent={fixedCustodyAgent}
      accounts={accounts}
    />
  </Stack>
}

// PropTypes
CreateModifyLoteDeAccion.propTypes = {
  intent: PropTypes.oneOf(['create', 'modify']).isRequired,
  initialLoteDeAccion: loteDeAccionPropType,
  fixedCustodyAgent: custodyAgentPropType,
  accounts: PropTypes.arrayOf(accountPropType).isRequired,
  custodyAgents: PropTypes.arrayOf(custodyAgentPropType).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CreateModifyLoteDeAccion;
// React imports
import { useState } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// My components imports
import { accionPropType } from '../../../utils/myPropTypes';
import AccionListItem from '../../basic/investment/accionListItem';

function AccionList({ acciones, onSelect }) {

  // State constants
  const [openedTickerIndex, setOpenedTickerIndex] = useState(undefined);

  // Handlers
  const handleFocusChange = (index) => {
    if (openedTickerIndex === index) {
      setOpenedTickerIndex(undefined);
    } else {
      setOpenedTickerIndex(index);
    }
  }

  const handleSelect = (accionId) => {
    // Return selected accion object searching inside acciones by id
    onSelect(acciones.find(accion => accion.id === accionId));
  }

  return acciones.map((accion, index) =>
    <AccionListItem
      key={index}
      accion={accion}
      displayDetails={openedTickerIndex === index}
      onFocus={() => handleFocusChange(index)}
      onSelect={() => handleSelect(accion.id)}
    />
  );
}

// PropTypes
AccionList.propTypes = {
  acciones: PropTypes.arrayOf(accionPropType).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default AccionList;
// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { scheduledCardMovementPropType } from '../../utils/myPropTypes';
import MovimientoTarjetaProgramadoListItemClassic from './movimientoTarjetaProgramadoListItemClassic';
import MovimientoTarjetaProgramadoListItemCompact from './movimientoTarjetaProgramadoListItemCompact';
import { generateListItemComponent } from '../../utils/visualizations';

function MovimientoTarjetaProgramadoListItem(props) {

  const ListItemComponent = generateListItemComponent();

  return (
    <ListItemComponent
      ClassicComponent={MovimientoTarjetaProgramadoListItemClassic}
      CompactComponent={MovimientoTarjetaProgramadoListItemCompact}
      confirmationText="Esta acción no se puede deshacer, si te arrepientes tendrás que volver a crear el registro programado. ¿Avanzamos?"
      {...props}
    />
  );
}

MovimientoTarjetaProgramadoListItem.propTypes = {
  movimiento: scheduledCardMovementPropType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  compact: PropTypes.bool,
};

export default MovimientoTarjetaProgramadoListItem;
// React imports
import { useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function SortBar(props) {

  // State constants
  const [sortBy, setSortBy] = useState(props.initialSortBy);
  const [sortAscending, setSortAscending] = useState(props.initiallySortAscending);

  // Handlers
  const handleSortTypeChange = (event, newSortType) => {
    let objectToReturn = {};
    setSortBy(prevSortType => {
      if (newSortType === null) {
        setSortAscending(prev => !prev);
        objectToReturn = {
          sortBy: prevSortType,
          sortAscending: !sortAscending,
        }
        return prevSortType;
      } else {
        setSortAscending(prev => prev);
        objectToReturn = {
          sortBy: newSortType,
          sortAscending: sortAscending,
        }
        return newSortType;
      }
    });
    props.onChange(objectToReturn);
  }

  return <ToggleButtonGroup
    value={sortBy}
    exclusive
    onChange={handleSortTypeChange}
    aria-label="sort by"
    color="primary"
    sx={{ display: "flex", flexDirection: "row", flexGrow: "1", marginBottom: "5px" }}
  >
    <ToggleButton value="monto" sx={{ display: "flex", flexDirection: "row", flexGrow: "1" }}>
      <Typography variant="caption">Monto</Typography>{sortBy === "monto" ? (sortAscending ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />) : <></>}
    </ToggleButton>
    <ToggleButton value="ganancia" sx={{ display: "flex", flexDirection: "row", flexGrow: "1" }}>
      <Typography variant="caption">Ganancia $</Typography>{sortBy === "ganancia" ? (sortAscending ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />) : <></>}
    </ToggleButton>
    <ToggleButton value="ganancia porcentual" sx={{ display: "flex", flexDirection: "row", flexGrow: "1" }}>
      <Typography variant="caption">Ganancia %</Typography>{sortBy === "ganancia porcentual" ? (sortAscending ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />) : <></>}
    </ToggleButton>
  </ToggleButtonGroup>
}

SortBar.propTypes = {
  onChange: PropTypes.func.isRequired, // Function to be called when user changes the sorting method
  initialSortBy: PropTypes.oneOf(["monto", "ganancia", "ganancia porcentual"]), // Value to initialize current sorting method
  initiallySortAscending: PropTypes.bool, // Value that initialize the sorting type
}

export default SortBar;
// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';

// My components imports
import { accionPropType } from '../../../utils/myPropTypes';
import AccionIcon from './accionIcon';


function AccionListItem({ accion, onFocus, onSelect, displayDetails = false }) {

  return <Accordion expanded={displayDetails} onChange={onFocus}>
    <AccordionSummary>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <AccionIcon accion={accion} />
          <Typography variant="body" sx={{ fontWeight: 'bold' }}>{'(' + accion.ticker + ')'}</Typography>
        </Stack>
        <Typography variant="body">{accion.nombre}</Typography>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      <Stack spacing={1}>
        <Typography variant="body" sx={{ fontStyle: "italic", textAlign: "justify" }}>
          {accion.descripcion ? accion.descripcion : "No hay descripción disponible"}
        </Typography>
        <Button variant="outlined" color="primary" onClick={onSelect}>Seleccionar</Button>
      </Stack>
    </AccordionDetails>
  </Accordion>


}

// PropTypes
AccionListItem.propTypes = {
  accion: accionPropType.isRequired,
  displayDetails: PropTypes.bool,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
}

export default AccionListItem;

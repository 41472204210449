// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import LoteDeAccionEnCuentaListItemCompact from './loteDeAccionEnCuentaListItemCompact';
import LoteDeAccionEnCuentaListItemClassic from './loteDeAccionEnCuentaListItemClassic';
import { generateListItemComponent } from '../../../utils/visualizations';
import { loteDeAccionPropType } from '../../../utils/myPropTypes';

function LoteDeAccionEnCuentaListItem({ onModify, onDelete, compact, ...props }) {

  const ListItemComponent = generateListItemComponent();

  return (
    <ListItemComponent
      ClassicComponent={LoteDeAccionEnCuentaListItemClassic}
      CompactComponent={LoteDeAccionEnCuentaListItemCompact}
      confirmationText="Esta acción no se puede deshacer, si te arrepientes deberás volver a registrar la compraventa de acciones. ¿Avanzamos?"
      compact={compact}
      onModify={onModify}
      onDelete={onDelete}
      loteDeAccion={props.loteDeAccion}
      {...props}
    />
  );

}

LoteDeAccionEnCuentaListItem.propTypes = {
  loteDeAccion: loteDeAccionPropType.isRequired, // value to initialize data inputs
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete LoteDeAccion
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify LoteDeAccion
  compact: PropTypes.bool, // If true, show compact version of LoteDeAccionListItem
};

export default LoteDeAccionEnCuentaListItem;
// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { scheduledMovementPropType } from '../../utils/myPropTypes';
import MovimientoProgramadoListItemClassic from './movimientoProgramadoListItemClassic';
import MovimientoProgramadoListItemCompact from './movimientoProgramadoListItemCompact';
import { generateListItemComponent } from '../../utils/visualizations';

function MovimientoProgramadoListItem({ movimiento, onDelete, onModify, compact }) {

  const ListItemComponent = generateListItemComponent();

  return (
    <ListItemComponent
      ClassicComponent={MovimientoProgramadoListItemClassic}
      CompactComponent={MovimientoProgramadoListItemCompact}
      confirmationText="Esta acción no se puede deshacer, si te arrepientes tendrás que volver a crear el registro programado. ¿Avanzamos?"
      compact={compact}
      onDelete={onDelete}
      onModify={onModify}
      movimiento={movimiento}
    />
  );
}

MovimientoProgramadoListItem.propTypes = {
  movimiento: scheduledMovementPropType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  compact: PropTypes.bool,
};

export default MovimientoProgramadoListItem;
// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Chip, Stack, Typography, Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTheme } from '@mui/material/styles';

// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { getAmountText, getCategoryIcon, parseName } from "../../utils/misc";
import { cardMovementPropType } from "../../utils/myPropTypes";

function MovimientoTarjetaListItemClassic({ movimientoTarjeta, onDelete, onModify, showInstallment }) {

  // State constants
  const [labelsToShow, setLabelsToShow] = useState([]);

  // Other constants
  const theme = useTheme();
  const ammount = showInstallment ? movimientoTarjeta.monto : movimientoTarjeta.monto * movimientoTarjeta.cantidad_de_cuotas;
  const ammountText = getAmountText(ammount, movimientoTarjeta.moneda);
  const commentText = movimientoTarjeta.comentario;
  const installmentsText = movimientoTarjeta.cantidad_de_cuotas > 1 ?
    (showInstallment ?
      '(cuota ' + movimientoTarjeta.numero_de_cuota + '/' + movimientoTarjeta.cantidad_de_cuotas + ')'
      : '(en ' + movimientoTarjeta.cantidad_de_cuotas + ' cuotas)'
    )
    : '';
  const cardName = movimientoTarjeta.tarjeta.nombre;
  const icon = getCategoryIcon(movimientoTarjeta.categoria.nombre, "small");

  // Effect hooks
  useEffect(() => {
    if (movimientoTarjeta) {
      let myLabels = JSON.parse(JSON.stringify(movimientoTarjeta.etiquetas));
      let labelsStack = myLabels.map(label => {
        return <Chip
          key={label.id}
          label={label.texto}
          variant="outlined"
          size="small"
          color="primary"
          sx={{ marginRight: "2px", marginBottom: "2px" }}
        />
      });
      setLabelsToShow(labelsStack);
    }
  }, [movimientoTarjeta])

  return <Stack direction="column" onClick={onModify} sx={{ padding: "25px", cursor: "pointer" }}>

    <Stack direction="column" spacing={1}>

      <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
        {movimientoTarjeta.movimiento_tarjeta_programado_id &&
          <Chip
            size="medium"
            icon={<SmartToyIcon fontSize="small" />}
            variant="outlined"
          />
        }
        <Chip
          // color="primary"
          label={movimientoTarjeta.fecha}
          size="medium"
          icon={<CalendarMonthIcon fontSize="small" />}
          variant="outlined"
        />
        <Chip
          // color="primary"
          label={parseName(cardName)}
          size="medium"
          icon={<CreditCardIcon fontSize="small" />}
          variant="outlined"
        />
      </Stack>
      <Stack direction="row">
        <Chip
          // color="primary"
          label={movimientoTarjeta.categoria.nombre}
          size="medium"
          icon={icon}
          variant="outlined"
        />
      </Stack>

    </Stack>
    <Stack direction="row" spacing={1}>
      <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
        <Typography sx={{
          fontWeight: 'bold',
          alignSelf: 'center',
          paddingBottom: '2px',
          fontSize: '1.1rem',
          color: movimientoTarjeta ? (movimientoTarjeta.monto < 0 ? theme.palette.error.main : theme.palette.success.main) : theme.palette.success.main
        }}
        >
          {ammountText}
        </Typography>
        <Typography variant="caption" sx={{ alignSelf: 'center', display: 'flex' }}>{installmentsText}</Typography>
      </Stack>
      <Box sx={{ display: "flex", flexGrow: "1", justifyContent: "flex-end", alignSelf: 'center' }}>
        <DeleteIcon onClick={onDelete} color="cancel" sx={{ fontSize: 35 }} />
      </Box>
    </Stack>
    <Stack direction="row" spacing={1} sx={{ marginBottom: "5px" }}>
      <Typography variant="subtitle2">{commentText}</Typography>
    </Stack>
    <div sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {labelsToShow}
    </div>
  </Stack>
}

MovimientoTarjetaListItemClassic.propTypes = {
  movimientoTarjeta: cardMovementPropType.isRequired, // value to initialize data inputs
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete MovimientoTarjeta
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify MovimientoTarjeta
  showInstallment: PropTypes.bool, // Indicates whether the installment number should be displayed or not
};

export default MovimientoTarjetaListItemClassic;
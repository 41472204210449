// React imports
import { useEffect, useState, useCallback } from 'react';

// MUI imports
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

// My components imports
import { urls } from "../../settings.js";
import { getUserAccounts, getCategories, getCards, getCustodyAgents, get } from "../../utils/api.js";
import NewMovementList from "../lists/newMovementList.js";
import FilterMovements from '../filters/filterMovements.js';
import MyMenuComponent from "./myMenuComponent.js";
import MyPaper from "../basic/myPaper.js";
import MyLoadingList from '../basic/myLoadingList.js';
import { useUserProfile } from '../../utils/userProfile.js';
import { emptyFilterMovement } from '../../utils/constants.js';
import { Alert, Snackbar } from '@mui/material';
import { getMixedMovementsListBasedOnQueryObject } from '../../utils/misc.js';

function SearchMovement(props) {

  // State constants
  const { profile } = useUserProfile();
  const [mixedMovements, setMixedMovements] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [cards, setCards] = useState([]);
  const [custodyAgens, setCustodyAgents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searching, setSearching] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [updateTable, setUpdatetable] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [currentQueryObject, setCurrentQueryObject] = useState(emptyFilterMovement);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let isMounted = true;
    Promise.all([getUserAccounts(), getCategories(), getCards(), getCustodyAgents()])
      .then(response => {
        if (isMounted) {
          setAccounts(response[0].results);
          setCategories(response[1].results);
          setCards(response[2].results);
          setCustodyAgents(response[3].results);
          setSearching(false);
        }
      })
      .catch(err => console.log(err));
    return () => { isMounted = false }
  }, [mixedMovements])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && ready) {
      getMixedMovementsListBasedOnQueryObject(currentQueryObject)
        .then(movs => {
          setMixedMovements(movs);
          setSearching(false);
        })
        .catch(err => {
          console.log("Some error ocurred", err);
          setShowErrorMessage(true)
        });
    }
    setReady(false);
    return () => { isMounted = false };
  }, [currentQueryObject, updateTable, ready])

  // Handlers
  const updateTableHandler = () => {
    setShowFilter(false);
    setSearching(true);
    setReady(true);
    setUpdatetable(!updateTable);
  };

  const searchMovementsHandler = useCallback(queryObject => {
    setShowFilter(false);
    setSearching(true);
    setReady(true);
    setCurrentQueryObject(queryObject);
  }, []);

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      // { url: urls.searchUrl, name: 'Buscar' },
    ]}
    currentPageName={'Actividad'}
  >
    <Snackbar open={showErrorMessage} autoHideDuration={2000} onClose={() => setShowErrorMessage(false)} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} tran>
      <Alert severity='error'>Algo no funcionó, intenta nuevamente</Alert>
    </Snackbar>

    <Stack direction="column" justifyContent="center" alignItems="center">

      {showFilter ?
        <FilterMovements
          initialValues={currentQueryObject}
          accounts={accounts}
          cards={cards}
          categories={categories}
          onSearchStarted={searchMovementsHandler}
        /> :
        <>
          <MyPaper elevation={1} sx={{ padding: "10px", marginBottom: "5px" }}>
            <Button
              variant="contained"
              onClick={() => setShowFilter(true)} fullWidth
            >Volver a buscar</Button>
          </MyPaper>
          {searching ? <MyPaper><MyLoadingList /></MyPaper> :
            <NewMovementList
              movements={mixedMovements}
              updateList={updateTableHandler}
              cards={cards}
              accounts={accounts}
              custodyAgents={custodyAgens}
              categories={categories}
              compact={profile.tipo_de_lista === 'ValidListTypes.compact'}
            />
          }
        </>
      }
    </Stack>

  </MyMenuComponent>



}

export default SearchMovement;
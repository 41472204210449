// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Alert, Divider, Stack, Typography } from "@mui/material";

// My components imports
import { getSubscriptions, cancelSubscription, getPaymentsReceipts } from "../../utils/billingApi";
import MyLoadingList from "../basic/myLoadingList";
import SubscriptionListItem from "../basic/subscriptionListItem";
import SubscriptionPaymentsReceiptsList from "../lists/subscriptionPaymentsReceiptsList";
import MercadoPagoCreateCard from "../forms/subscriptions/mercadoPagoCreateCard";


function SubscriptionsHandler(props) {

  // State constants
  const [subscription, setSubscription] = useState(null);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [errorLoadingSubscription, setErrorLoadingSubscription] = useState(false);
  const [loadingReceipts, setLoadingReceipts] = useState(false);
  const [errorLoadingReceipts, setErrorLoadingReceipts] = useState(false);
  const [receipts, setReceipts] = useState([]);

  // Auxiliary functions
  const updateSubscription = () => {
    setLoadingSubscriptions(true);
    getSubscriptions()
      .then(response => {
        console.log(response);
        setSubscription(response.results[0]);
        setLoadingSubscriptions(false);
      })
      .catch(error => {
        setLoadingSubscriptions(false);
        setErrorLoadingSubscription(true);
        console.log(error);
      });
  }

  const udpateReceipts = () => {
    setLoadingReceipts(true);
    getPaymentsReceipts(subscription.id)
      .then(response => {
        setReceipts(response.results);
        setLoadingReceipts(false);
      })
      .catch(error => {
        setLoadingReceipts(false);
        setErrorLoadingReceipts(true);
        console.log(error);
      });
  }


  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    // Get user subscriptions
    isMounted && setErrorLoadingSubscription(false);
    isMounted && updateSubscription();
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    let isMounted = true;
    // Get user subscriptions
    isMounted && setErrorLoadingReceipts(false);
    isMounted && subscription && udpateReceipts();
    return () => { isMounted = false };
  }, [subscription]);

  // Handlers
  const cancelSubscriptionHandler = reason => {
    console.log('Cancelando suscripción');
    setLoadingSubscriptions(true);
    cancelSubscription(subscription.id, reason)
      .then(response => {
        updateSubscription();
      })
      .catch(error => {
        console.log(error);
        setLoadingSubscriptions(false);
      });
  }

  return loadingSubscriptions ? <MyLoadingList /> : (
    errorLoadingSubscription ? <Alert severity="error">No se pudo obtener la información de suscripción</Alert> :
      subscription ?
        <Stack>
          <SubscriptionListItem subscription={subscription} onCancelSubscription={cancelSubscriptionHandler} />
          {loadingReceipts ?
            <MyLoadingList /> :
            (errorLoadingReceipts ?
              <Alert severity="error">No se pudo obtener la información de recibos de pago</Alert> :
              <Stack>
                <Divider />
                <Typography variant="body1" fontWeight={'bold'} sx={{ paddingLeft: "15px", marginTop: "15px" }}>Recibos de pago</Typography>
                <SubscriptionPaymentsReceiptsList subscriptionPaymentsReceipts={receipts} />
              </Stack>
            )}
          <MercadoPagoCreateCard />
        </Stack> :
        <Alert severity="info">No tienes suscripciones activas</Alert>
  )
}

export default SubscriptionsHandler;
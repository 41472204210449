// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Autocomplete, Chip, TextField, Stack } from "@mui/material";

// My components imports
import { getCustodyAgentTypeIcon } from "../../utils/misc";

// Auxiliary functions
function pushCustodyAgent(agent, list) {
  let alreadyIn = false;
  list.forEach(item => {
    if (item.id === agent.id) {
      alreadyIn = true;
    }
  })
  if (!alreadyIn) {
    list.push(agent);
  }
  return list;
}

function CustodyAgentPicker(props) {

  // Constants
  const multiple = props.multiple === undefined ? true : props.multiple;

  // State constants
  const [selectedAgents, setSelectedAgents] = useState(props.selectedAgents ? props.selectedAgents : []);
  const [selectedAgent, setSelectedAgent] = useState(props.selectedAgents ? props.selectedAgents : null);
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      if (multiple) {
        props.onChange(selectedAgents);
      } else {
        props.onChange(selectedAgent);
      }
    }
  }, [selectedAgents, selectedAgent, ready])

  return <Autocomplete
    fullWidth
    multiple={multiple}
    filterSelectedOptions
    isOptionEqualToValue={(option, value) => option.id === value.id}
    clearOnBlur
    id="typeId"
    options={props.agents}
    disabled={props.disabled}
    getOptionLabel={type => {
      if (type !== null && type.nombre !== undefined) {
        return type.nombre;
      } else {
        return null;
      }
    }}
    renderOption={(props, option) => {
      return <Stack direction="row" spacing={3} {...props}>
        {getCustodyAgentTypeIcon(option.tipo.nombre, "medium")}
        {option.nombre}
      </Stack>
    }}
    value={multiple ? selectedAgents : selectedAgent}
    onChange={(event, newValue) => {
      if (multiple) {
        // First make a copy of the object
        let myNewList = JSON.parse(JSON.stringify(newValue));

        const lastItem = myNewList.pop();

        // if lastItem is undefined set selectedAgents to empty list
        if (typeof lastItem === 'undefined') {
          setSelectedAgents([]);
          setReady(true);
          return null;
        };

        // User is trying to add an existing label, make sure the label isn't already selected
        setSelectedAgents(pushCustodyAgent(lastItem, myNewList));
        setReady(true);
      } else {
        setSelectedAgent(newValue);
        setReady(true);
      }

    }}

    renderTags={(tagValue, getTagProps) =>
      tagValue.map((agent, index) => (
        <Chip
          label={agent.nombre}
          {...getTagProps({ index })}
          variant="outlined"
          color="primary"
        />
      )
      )
    }

    renderInput={(params) => <TextField
      {...params}
      label={multiple ? "Agentes de custodia" : "Agente de custodia"}
      placeholder={multiple ? "Selecciona los agentes" : "Selecciona el agente"}
    />
    }
  />
}

CustodyAgentPicker.propTypes = {
  selectedAgents: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]), // Values to initialize selection
  onChange: PropTypes.func.isRequired, // Function to be called when values changes
  agents: PropTypes.arrayOf(PropTypes.object).isRequired, // All available Agents
  multiple: PropTypes.bool, // Default: true, Indicates whether multiple selection is allowed
  disabled: PropTypes.bool, // Default: false, Indicates whether the picker is disabled
}


export default CustodyAgentPicker;
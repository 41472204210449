// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Chip, Stack, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useTheme } from "@mui/material";

// My component imports
import { getAmountText, getCategoryIcon } from "../../utils/misc";
import { cardMovementPropType } from "../../utils/myPropTypes";
import { dateToDisplayFormat } from "../../utils/date";

function MovimientoTarjetaListItemCompact({ movimientoTarjeta, onDelete, onModify, showInstallment }) {

  // Other constants
  const theme = useTheme();
  const ammount = showInstallment ? movimientoTarjeta.monto : movimientoTarjeta.monto * movimientoTarjeta.cantidad_de_cuotas;
  const ammountText = getAmountText(ammount, movimientoTarjeta.moneda);
  const installmentsText = movimientoTarjeta.cantidad_de_cuotas > 1 ?
    (showInstallment ?
      '(cuota ' + movimientoTarjeta.numero_de_cuota + '/' + movimientoTarjeta.cantidad_de_cuotas + ')'
      : '(en ' + movimientoTarjeta.cantidad_de_cuotas + ' cuotas)'
    )
    : '';
  const ammountColor = ammount >= 0 ? theme.palette.number.positive : theme.palette.number.negative;

  return <Stack direction="row" spacing={2} sx={{ cursor: "pointer" }} onClick={onModify}>

    <Stack direction="column" justifyContent={"center"} sx={{ color: theme.palette.primary.main }}>
      {getCategoryIcon(movimientoTarjeta ? movimientoTarjeta.categoria.nombre : null, "medium")}
    </Stack>
    <Stack direction="column">
      <Stack direction="row" flexWrap={"wrap"}>
        {movimientoTarjeta.etiquetas.map(label => {
          return <Chip
            key={label.id}
            label={label.texto}
            variant="outlined"
            color="primary"
            size="small"
            sx={{ marginRight: "2px", marginBottom: "2px", fontSize: "0.7rem" }}
          />
        })}
      </Stack>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        {movimientoTarjeta.movimiento_tarjeta_programado_id && <SmartToyIcon sx={{ fontSize: 15, color: theme.palette.disabled.main }} />}
        <Typography variant="caption">{dateToDisplayFormat(movimientoTarjeta.fecha) + ' - Tarjeta: ' + movimientoTarjeta.tarjeta.nombre}</Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography variant="subtitle1" color={ammountColor} sx={{ fontWeight: "bold" }}>{ammountText}</Typography>
        <Typography variant="caption">{installmentsText}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="caption" sx={{ fontStyle: "italic", color: theme.palette.lightText }}>{movimientoTarjeta.comentario}</Typography>
      </Stack>
    </Stack>
    <Stack direction="column" justifyContent={"center"} alignItems={"flex-end"} flexGrow={1}>
      <DeleteIcon onClick={onDelete} color="cancel" sx={{ fontSize: 25 }} />
    </Stack>
  </Stack>
}

MovimientoTarjetaListItemCompact.propTypes = {
  movimientoTarjeta: cardMovementPropType.isRequired, // value to initialize data inputs
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete MovimientoTarjeta
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify MovimientoTarjeta
  showInstallment: PropTypes.bool, // Indicates whether the installment number should be displayed or not
};

export default MovimientoTarjetaListItemCompact;
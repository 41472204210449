// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Dialog, DialogContent, Stack } from "@mui/material";

// My components imports
import { emptyCardPayment } from "../../utils/constants.js";
import { deleteCardPayment } from "../../utils/api.js";
import RegisterCardPayment from "../app/registerCardPayment.js";
import DisplayCardPaymentList from "./displayCardPaymentList.js";
import { cardPaymentPropType } from "../../utils/myPropTypes.js";

function CardPaymentList(props) {

  // State constants
  const [initialCardPayment, setInitialCardPayment] = useState(emptyCardPayment);
  const [showCardPaymentForm, setShowCardPaymentForm] = useState(false);
  const [paymentsList, setPaymentsList] = useState(props.paymentsList);


  // Effect hooks
  useEffect(() => {
    if (initialCardPayment.saldo_de_tarjeta_id) {
      setShowCardPaymentForm(true);
    }
  }, [initialCardPayment])

  useEffect(() => {
    setPaymentsList(props.paymentsList);
  }, [props.paymentsList])

  // Handlers
  const handleDeleteConfirmation = (payment) => {
    deleteCardPayment(payment.id)
      .then(result => {
        // Ask the parent component to update the cardPayment list
        props.updateList(null);
      })
      .catch(err => console.log(err));
  }

  const handleModifyPaymentClick = payment => {
    setInitialCardPayment(payment);
  }

  const modifiedPaymentHandler = mov => {
    setInitialCardPayment(emptyCardPayment);
    setShowCardPaymentForm(false);
    props.updateList();
  }

  return <Stack spacing={1}>
    {
      showCardPaymentForm ?
        <Dialog onClose={() => setShowCardPaymentForm(false)} open={showCardPaymentForm}>
          <DialogContent>
            <RegisterCardPayment
              card={props.card}
              accounts={props.accounts}
              onSubmit={modifiedPaymentHandler}
              initialValues={{
                initialCardPayment: initialCardPayment,
                intent: "modify"
              }}
            />
          </DialogContent>
        </Dialog>
        :
        <Stack>
          <DisplayCardPaymentList
            paymentsList={paymentsList}
            card={props.card}
            onDelete={handleDeleteConfirmation}
            onModify={handleModifyPaymentClick}
            compact={props.compact}
          />
        </Stack>
    }
  </Stack>
}

CardPaymentList.propTypes = {
  paymentsList: PropTypes.arrayOf(cardPaymentPropType), // List of movements to be diplayed
  updateList: PropTypes.func, // Function to be called when a the list needs to be updated
  card: PropTypes.object.isRequired, // Current card
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available accounts
  compact: PropTypes.bool, // If true, show compact version of the list, otherwise show classic version. Default is false
}

export default CardPaymentList;

// React imports
import { useEffect, useState } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Typography, Table, TableBody, TableRow, TableCell, Stack } from '@mui/material';
import TouchAppIcon from '@mui/icons-material/TouchApp';

// My component imports
import { accionEnPortafolioPropType, stockQuotePropType } from '../../../utils/myPropTypes';
import { calculatePortfolioHoldingsValues } from '../../../utils/investment/misc';
import { getAmountText, getPercentageText } from '../../../utils/misc';
import { getAmountDisplayColor } from '../../../utils/misc';


function AccionPortafolioSummary({ portafolio, quoteObject, onClick }) {

  const [holdingsValues, setHoldingsValues] = useState(calculatePortfolioHoldingsValues(portafolio, quoteObject));
  const [totalHoldingsText, setTotalHoldingsText] = useState(0);
  const [totalGrowthText, setTotalGrowthText] = useState(0);
  const [totalPercentageGrowthText, setTotalPercentageGrowthText] = useState(0);
  const [totalCostText, setTotalCostText] = useState(0);
  const [moneda, setMoneda] = useState(null);
  const [growthSign, setGrowthSign] = useState("+");

  useEffect(() => {
    let isMounted = true;
    if (isMounted && portafolio.length > 0) {
      setMoneda(portafolio[0].batches[0].cuenta.moneda);
    }
    return () => { isMounted = false };
  }, [portafolio]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setHoldingsValues(calculatePortfolioHoldingsValues(portafolio, quoteObject));
    }
    return () => { isMounted = false };
  }, [portafolio, quoteObject]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && moneda) {
      setTotalHoldingsText(getAmountText(holdingsValues.currentHoldingsValue, moneda));
      setTotalGrowthText(getAmountText(holdingsValues.growthValue, moneda));
      setTotalCostText(getAmountText(-holdingsValues.actualCosts, moneda));
      setTotalPercentageGrowthText(getPercentageText(holdingsValues.growthPercentage));
      setGrowthSign(holdingsValues.growthValue >= 0 ? "+" : "-");
    } else {
      setTotalHoldingsText("N/A");
      setTotalGrowthText("N/A");
      setTotalCostText("N/A");
      setTotalPercentageGrowthText("N/A");
      setGrowthSign("+");
    }
    return () => { isMounted = false };
  }, [holdingsValues, moneda]);

  return <Stack direction="column" spacing={2} onClick={onClick} sx={{ cursor: "pointer" }}>
    <Stack direction="row" sx={{ paddingLeft: "10px" }} spacing={1}>
      <TouchAppIcon color='primary' />
      <Stack direction="row" alignItems={"flex-end"}>
        <Typography variant="caption" fontStyle={"italic"} sx={{ marginTop: "7px" }}>(Mostrar / ocultar la lista de acciones y ETFs)</Typography>
      </Stack>
    </Stack>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell size="small" align="left" sx={{ borderBottom: "none" }}>Costo:</TableCell>
          <TableCell size="small" align="right" sx={{ borderBottom: "none" }}>
            <Typography variant="body" fontWeight={"bold"}>{totalCostText}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell size="small" align="left">Ganancia USD:</TableCell>
          <TableCell size="small" align="right">
            <Typography variant="body" color={getAmountDisplayColor(holdingsValues.growthValue)} fontWeight={"bold"}>{growthSign} {totalGrowthText}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell size="small" align="left">Valor actual:</TableCell>
          <TableCell size="small" align="right">
            <Typography variant="body" fontWeight={"bold"}>{totalHoldingsText}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell size="small" align="left" sx={{ borderBottom: "none" }}>Ganancia (%):</TableCell>
          <TableCell size="small" align="right" sx={{ borderBottom: "none" }}>
            <Typography variant="body" color={getAmountDisplayColor(holdingsValues.growthValue)} fontWeight={"bold"}> {growthSign} {totalPercentageGrowthText}</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Stack>
}

AccionPortafolioSummary.propTypes = {
  portafolio: PropTypes.arrayOf(accionEnPortafolioPropType).isRequired,
  quoteObject: PropTypes.arrayOf(stockQuotePropType).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AccionPortafolioSummary;
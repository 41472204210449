import { get, post, put, deleteResource } from "./api";
import { investmentApiEndpoints, investmentEndpointNames, apiEndpoints } from "../settings";
import { getAccessToken } from "./authUtils";
import { purgeQueryObject } from "./misc";

class TickerSearch {
  constructor(searchString, limit) {
    this.searchString = searchString;
    this.limit = limit;
    this.nextUrl = investmentApiEndpoints.tickers;
  }

  async next() {
    if (!this.nextUrl) {
      throw new Error("No more pages to fetch");
    }

    // See if nextUrl already has query parameters and deconstruct "start" value
    const url = new URL(this.nextUrl);
    const searchParams = url.searchParams;
    const start = searchParams.get("start");
    // Remove searchParams from nextUrl
    url.search = "";
    this.nextUrl = url.toString();

    const response = await get(this.nextUrl, "Error searching for tickers", {
      filter_by: this.searchString,
      limit: this.limit,
      start: start ? start : 1,
    });

    this.nextUrl = response.next;
    return response;
  }

  hasNext() {
    return this.nextUrl ? true : false;
  }
}

async function getWithNo403(url, errorMessage, queryObject) {
  // todo: implementar pagination
  const jwtToken = await getAccessToken();
  const completeUrl = queryObject ? url + '?' + new URLSearchParams(purgeQueryObject(queryObject)).toString() : url;
  // Executing the request
  const response = await fetch(completeUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + jwtToken,
    },
  })

  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return {
      results: [],
      ok: false,
      code: 404,
    };
  } else if (response.status === 403) {
    return {
      results: [],
      ok: false,
      code: 404,
    };
  } else {
    throw new Error(errorMessage);
  }
}

function registerStockBatch(stockBatch) {
  const body = {
    usuario_id: stockBatch.account.usuario_id,
    fecha: stockBatch.date,
    cantidad: stockBatch.quantity,
    precio: stockBatch.price,
    cuenta_id: stockBatch.account.id,
    agente_de_custodia_id: stockBatch.custodyAgent.id,
  };
  const url = investmentApiEndpoints.tickers + "/" + stockBatch.stock.id + investmentEndpointNames.stockBatches;
  return post(url, body, "Error registering stock batch");
}

function modifyStockBatch(stockBatch) {
  const body = {
    fecha: stockBatch.date,
    cantidad: stockBatch.quantity,
    precio: stockBatch.price,
    cuenta_id: stockBatch.account.id,
    agente_de_custodia_id: stockBatch.custodyAgent.id,
  };
  const url = investmentApiEndpoints.stockBatches + "/" + stockBatch.id;
  return put(url, body, "Error modifying stock batch");
}

function getStockBatches(custodyAgent, accounts = []) {
  const custodyAgentId = custodyAgent ? custodyAgent.id : null;
  const queryObject = custodyAgentId ? { filter_by_custody_agent: custodyAgentId } : {};
  if (accounts.length > 0) {
    queryObject.filter_by_account = accounts;
  }
  return getWithNo403(investmentApiEndpoints.stockBatches, "Error fetching stock batches", queryObject);
}

function deleteStockBatch(stockBatchId) {
  const url = investmentApiEndpoints.stockBatches + "/" + stockBatchId;
  return deleteResource(url, "Error deleting stock batch");
}

function getStockLastQuote(stock) {
  const url = investmentApiEndpoints.tickers + "/" + stock.id + investmentEndpointNames.stockQuotes;
  return get(url, "Error fetching last quote");
}


export { TickerSearch, registerStockBatch, getStockBatches, getStockLastQuote, deleteStockBatch, modifyStockBatch };
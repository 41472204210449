// React imports
import { useEffect, useState } from "react";

// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import Stack from '@mui/material/Stack';
import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// My components imports
import { urls } from "../../../settings.js";
import MyMenuComponent from "../myMenuComponent.js";
import LoteDeAccionManager from "./loteDeAccionManager.js";
import { getUserAccounts } from "../../../utils/api.js";
import { getAmountText } from "../../../utils/misc.js";
import MyColumnStack from "../../basic/myColumnStack.js";



function PortfolioPage(props) {

  // State constants

  // Other constants
  const history = useHistory();

  // State hooks
  const [accounts, setAccounts] = useState([]);

  // Effects
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getUserAccounts()
        .then(response => {
          setAccounts(response.results);
        })
        .catch((error) => console.error(error));
    }
    return () => { isMounted = false }
  }, []);

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.investmentsUrl, name: 'Inversiones' },
    ]}
    currentPageName={'Portafolio'}
  >
    <MyColumnStack spacing={1} alignItems="center">
      <Paper sx={{ width: "100%", padding: "10px" }}>
        <Typography variant="h6">Portafolio de Inversiones</Typography>
        <Stack direction="row" spacing={1}>
          <Typography variant="body1">Valor actual:</Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>{getAmountText(1500000, { nombre: "Dolar", nombre_corto: "USD" })}</Typography>
        </Stack>
      </Paper>
      <Accordion sx={{ width: "100%", padding: "10px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">Accciones y ETFs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LoteDeAccionManager accounts={accounts} onChange={() => console.log("CHANGED!!!")} />
        </AccordionDetails>
      </Accordion>
    </MyColumnStack>
  </MyMenuComponent >


}

export default PortfolioPage;
// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import BarChartIcon from '@mui/icons-material/BarChart';

// My components imports
import { urls } from "../../../settings.js";
import MyMenuComponent from "../myMenuComponent.js";
import MyPaper from "../../basic/myPaper.js";
import MyListItem from "../../basic/myListItem.js";


function Investments(props) {

  // State constants

  // Other constants
  const history = useHistory();

  // State hooks

  // Change handlers

  return <div>
    <MyMenuComponent
      links={[
        { url: urls.homeUrl, name: 'Inicio' },
      ]}
      currentPageName={'Inversiones'}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <MyPaper>
          <Stack spacing={1}>
            <List >

              <MyListItem onClick={event => history.push(urls.portafolioUrl)}>
                <ListItemAvatar>
                  <Avatar>
                    <BarChartIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Portafolio" secondary="Gestiona tu portafolio" />
              </MyListItem>


            </List>
          </Stack>
        </MyPaper>
      </Stack>
    </MyMenuComponent>

  </div>
}

export default Investments;
// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Chip, Stack, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useTheme } from "@mui/material";

// My components imports
import MyDialog from "./MyDialog.js";
import { getAmountText } from "../../utils/misc.js";
import { dateToDisplayFormat } from "../../utils/date.js";
import { transferPropType } from "../../utils/myPropTypes.js";

function TransferenciaListItemCompact({ transferencia, onDelete, onModify, pointOfView }) {

  // State constants
  const [isCurrencyExchange, setIsCurrencyExchange] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  // Other constants
  const theme = useTheme();
  const srcAmmountText = getAmountText(transferencia.monto_origen, transferencia.cuenta_origen.moneda)
  const srcAcctName = transferencia.cuenta_origen.nombre + " (" + transferencia.cuenta_origen.moneda.nombre_corto + ")";
  const dstAmmountText = getAmountText(transferencia.monto_destino, transferencia.cuenta_destino.moneda)
  const dstAcctName = transferencia.cuenta_destino.nombre + " (" + transferencia.cuenta_destino.moneda.nombre_corto + ")";
  const commentText = transferencia.comentario;
  const perspective = pointOfView ? (transferencia.cuenta_origen.id === pointOfView.id ? "sourceAccount" : "destinationAccount") : "sourceAccount";
  const accountText = perspective === "sourceAccount" ? srcAcctName : dstAcctName;
  const ammountColor = perspective === "sourceAccount" ?
    (transferencia.monto_origen >= 0 ? theme.palette.number.positive : theme.palette.number.negative) :
    (transferencia.monto_destino >= 0 ? theme.palette.number.positive : theme.palette.number.negative);


  // Effect hooks
  useEffect(() => {
    if (transferencia) {
      if (transferencia.cuenta_origen.moneda.id === transferencia.cuenta_destino.moneda.id) {
        setIsCurrencyExchange(false);
      } else {
        setIsCurrencyExchange(true);
      }
    }
  }, [transferencia])

  return <Stack direction="row" spacing={2} onClick={onModify} sx={{ cursor: "pointer" }}>

    <Stack direction="column" justifyContent="center">
      {isCurrencyExchange ?
        <CurrencyExchangeIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} /> :
        <SyncAltIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />}
    </Stack>

    <Stack direction={"column"} justifyContent={"center"}>

      <Stack direction="row" flexWrap={"wrap"}>
        {transferencia.etiquetas.map(label => {
          return <Chip
            key={label.id}
            label={label.texto}
            variant="outlined"
            color="primary"
            size="small"
            sx={{ marginRight: "2px", marginBottom: "2px", fontSize: "0.7rem" }}
          />
        })}
      </Stack>

      <Stack direction="row" spacing={0.5} flexWrap={"wrap"}>
        <Typography variant="caption">{dateToDisplayFormat(transferencia.fecha) + ' - ' + accountText}</Typography>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Typography variant="subtitle1" color={ammountColor} sx={{ fontWeight: "bold" }}>
          {perspective === "destinationAccount" ?
            getAmountText(transferencia.monto_destino, transferencia.cuenta_destino.moneda) :
            getAmountText(transferencia.monto_origen, transferencia.cuenta_origen.moneda)
          }
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Typography variant="caption" sx={{ fontStyle: "italic", color: theme.palette.primary.lightText }}>{commentText}</Typography>
      </Stack>

    </Stack>

    <Stack justifyContent="center" alignItems={"flex-end"} flexGrow={1}>
      <MyDialog
        onClose={() => setInfoDialogOpen(false)}
        open={infoDialogOpen}
        onOpen={() => setInfoDialogOpen(true)}
        title="¿Cuál es la otra cuenta?"
        iconSize={"medium"}
      >
        {perspective === "destinationAccount" ?
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
            <Stack justifyContent="center">
              <Typography variant="body2">
                {isCurrencyExchange ? ("El monto fue cambiado por " + srcAmmountText + " que vinieron de la cuenta " + srcAcctName)
                  :
                  ("El monto se transfirió desde la cuenta " + srcAcctName)}
              </Typography>
            </Stack>
          </Stack>
          :
          <Stack spacing={1} sx={{ flexWrap: 'wrap' }}>
            <Stack direction="row" justifyContent="center">
              <Typography variant="body2">
                {isCurrencyExchange ? ("El monto fue cambiado por " + dstAmmountText + " que fueron a la cuenta " + dstAcctName)
                  :
                  ("El monto se transfirió hacia la cuenta " + dstAcctName)}
              </Typography>
            </Stack>

          </Stack>
        }
      </MyDialog>
    </Stack>
    <Stack direction="column" justifyContent={"center"} alignItems={"flex-end"}>
      <DeleteIcon onClick={onDelete} color="cancel" sx={{ fontSize: 25 }} />
    </Stack>

  </Stack >
}

TransferenciaListItemCompact.propTypes = {
  transferencia: transferPropType.isRequired, // Transferencia object to be shown
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete Movimiento
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify Movimiento
  pointOfView: PropTypes.object, // Account object that determines from which perspective to show transfer or currency exchange
};

export default TransferenciaListItemCompact;
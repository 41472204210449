// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import MovimientoTarjetaListItemCompact from './movimientoTarjetaListItemCompact';
import MovimientoTarjetaListItemClassic from "./movimientoTarjetaListItemClassic";
import { cardMovementPropType } from '../../utils/myPropTypes';
import { generateListItemComponent } from '../../utils/visualizations';

function MovimientoTarjetaListItem({ movimientoTarjeta, onDelete, onModify, showInstallment, compact }) {

  const ListItemComponent = generateListItemComponent();

  return (
    <ListItemComponent
      ClassicComponent={MovimientoTarjetaListItemClassic}
      CompactComponent={MovimientoTarjetaListItemCompact}
      confirmationText="Esta acción no se puede deshacer, si te arrepientes tendrás que volver a registrar el gasto. ¿Avanzamos?"
      compact={compact}
      onDelete={onDelete}
      onModify={onModify}
      movimientoTarjeta={movimientoTarjeta}
      showInstallment={showInstallment}
    />
  );
}

MovimientoTarjetaListItem.propTypes = {
  movimientoTarjeta: cardMovementPropType, // value to initialize data inputs
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete MovimientoTarjeta
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify MovimientoTarjeta
  showInstallment: PropTypes.bool, // Indicates whether the installment number should be displayed or not
  compact: PropTypes.bool, // Indicates if the component should be displayed in compact mode
};

export default MovimientoTarjetaListItem;
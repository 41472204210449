// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { movementPropType } from "../../utils/myPropTypes";
import MovimientoListItemCompact from "./movimientoListItemCompact";
import MovimientoListItemClassic from "./movimientoListItemClassic";
import { generateListItemComponent } from '../../utils/visualizations';

function MovimientoListItem({ movimiento, onModify, onDelete, ...props }) {

  const ListItemComponent = generateListItemComponent();

  return (
    <ListItemComponent
      ClassicComponent={MovimientoListItemClassic}
      CompactComponent={MovimientoListItemCompact}
      confirmationText={"Esta acción no se puede deshacer, si te arrepientes deberás volver a registrar el movimiento. ¿Avanzamos?"}
      onModify={onModify}
      onDelete={onDelete}
      movimiento={movimiento}
      {...props}
    />
  );
}

MovimientoListItem.propTypes = {
  movimiento: movementPropType.isRequired, // value to initialize data inputs
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete Movimiento
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify Movimiento
  compact: PropTypes.bool, // If true, show compact version of MovimientoListItem
};

export default MovimientoListItem;